import { useRoutes } from "react-router-dom";

// project import
import LoginRoutes from "./LoginRoutes";
import MainRoutes, { OperationRoutes, SalesRoutes } from "./MainRoutes";
import { useAuth } from "../App";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const auth = useAuth();
  
  let routes = [LoginRoutes];
  if ([1, 2, 3].includes(auth?.user?.usertypeid)) routes = [LoginRoutes, MainRoutes];
  else if (auth?.user?.usertypeid === 4) routes = [LoginRoutes, OperationRoutes];
  else if (auth?.user?.usertypeid === 5) routes = [LoginRoutes, SalesRoutes];
  else routes = [LoginRoutes];

  return useRoutes(routes);
}
