import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";

import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  textFieldProps,
} from "../../common/controlProps";
import { useVendorMasterHooks } from "./useVendorMasterHooks";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import { Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";
import DeleteModal from "../../components/DeleteModal";

const VendorMaster = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    formik,
    vendor_list,
    actions,
    column,
    formData,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useVendorMasterHooks();

  return (
    <MainCard title="Vendor Master">
      <Grid container spacing={1}>
        <Grid item xl={12} xs={12} sm={12} md={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Company"
                  name="company"
                  autoComplete="off"
                 onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.company}
                  error={formik.errors.company}
                  helperText={formik.touched.company && formik.errors?.company}
                />
              </Grid>

              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="TAN"
                  name="tan"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.tan}
                  error={formik.errors.tan}
                  helperText={formik.touched.tan && formik.errors?.tan}
                />
              </Grid>


              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="GST No"
                  name="gstno"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.gstno}
                  error={formik.errors.gstno}
                  helperText={formik.touched.gstno && formik.errors?.gstno}
                />
              </Grid>

              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Address"
                  name="address"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  error={formik.errors.address}
                  helperText={formik.touched.address && formik.errors?.address}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Contact Person"
                  name="contact_person"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.contact_person}
                  error={formik.errors.contact_person}
                  helperText={formik.touched.contact_person && formik.errors?.contact_person}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Contact Phone"
                  name="contact_phone"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.contact_phone}
                  error={formik.errors.contact_phone}
                  helperText={formik.touched.contact_phone && formik.errors?.contact_phone}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Contact Email"
                  name="contact_email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.contact_email}
                  error={formik.errors.contact_email}
                  helperText={formik.touched.contact_email && formik.errors?.contact_email}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} >
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Description"
                  name="description"
                  multiline
                  rows={3}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  error={formik.errors.description}
                  helperText={formik.touched.description && formik.errors?.description}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="submit"
                  color="success"
                  startIcon={<Save />}
                >
                  Save
                </StyledButton>
              </Grid>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="button"
                  color="error"
                  startIcon={<Cancel />}
                  onClick={actions.resetForm}
                >
                  {`Cancel`}
                </StyledButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      <Grid item xl={12} md={12} sm={12} className="border-1">
        <div className="border border-secondary-subtle mt-2">
          <TableContainer
            columns={column}
            data={vendor_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      </Grid>
      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
    </MainCard>
  );
};

export default VendorMaster;
