import { keys } from '../../config/keys';
import axios from '../../common/axios';
import { _dispatchPayload } from './dispatchPayload';
import { PURCHASE_LIST } from '../constTypes';

export const get_purchase_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/purchase`)
    .then((res) => {
      
      dispatch(_dispatchPayload(PURCHASE_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const save_purchase = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/purchase`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_purchase = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/purchase/delete`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};