import { keys } from "../../config/keys";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../common/axios";
import { _dispatchPayload } from "./dispatchPayload";
import { ATTRIBUTE_MASTER_VALUE_LIST, PRODUCT_LIST } from "../constTypes";

export const getProductList = createAsyncThunk("getProductList", async () => {
  const ProductList = await fetch(`${keys.ServicePath}/product/products`).then(
    (res) => res.json()
  );

  return ProductList;
});

export const postProductList = createAsyncThunk(
  "postProductList",
  async (productData) => {
    const postProductListResponse = await fetch(
      `${keys.ServicePath}/product/products/add`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(productData)
      }
    ).then((res) => res.json());

    return postProductListResponse;
  }
);

export const get_product_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/product`)
    .then((res) => {
      let data = res.data.rows[0].map((item) => {
        let t = res.data.rows[1].filter((x) => x.productid === item.id);
        let at = res.data.rows[2].filter((x) => x.productid === item.id);
        let fbt = res.data.rows[3]
          .filter((x) => x.productid === item.id)
          .map((y) => y.refproductid);

        let freq_prd = res.data.rows[0].filter((x) => fbt.includes(x.id));
        let prod_spec = res.data.rows[4].filter((x) => x.productid === item.id);
        item.product_images = t;
        item.attribute_values = at;
        item.frequent_products = freq_prd;
        item.product_specifications = prod_spec;
        return item;
      });

      dispatch(_dispatchPayload(PRODUCT_LIST, data));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const store_product_dd = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/product/store_product_dd`)
    .then((res) => {
      dispatch(_dispatchPayload(PRODUCT_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const save_product = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/product`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_product = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/product/delete`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_product_images = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/product/delete_photo`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_attribute_master_value_list =
  (callback) => async (dispatch) => {
    await axios
      .get(`${keys.ServicePath}/api/product/attribute_master_value_list`)
      .then((res) => {
        let data = res.data.rows[0].map((item) => {
          let t = res.data.rows[1].filter((x) => x.attributeid === item.id);
          item.attributevalues = t;
          return item;
        });

        dispatch(_dispatchPayload(ATTRIBUTE_MASTER_VALUE_LIST, data));
        if (callback?.onSuccess) callback.onSuccess(res.data);
      })
      .catch((e) => {
        if (callback?.onError) callback?.onError(e);
      });
  };
