import axios from "../../common/axios";
import { keys } from "../../config/keys";

export const login = (loginData, callback) => async (dispatch) => {
    
  await axios
    .post(`${keys.ServicePath}/api/auth/login`, loginData)
    .then((res) => {
      if (!res.data?.success) {
        if (callback?.onError) callback?.onError(res.data);
      } else {
        if (callback?.onSuccess) {
          callback?.onSuccess(res);
        }
      }
    })
    .catch((e) => {});
};
