/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userValidationSchema } from "./validationSchema";
import { useFormik } from "formik";
import {
  delete_user,
  get_user_list,
  get_user_type_list,
  save_user
} from "../../store/actions/userAction";
import { GridAction } from "../../common/useGridActions";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Circle,
  Image
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { keys } from "../../config/keys";
import { ImageIcon } from "../../Icons";

const initialValues = {
  id: 0,
  userName: "",
  usertypeid: 0,
  userType: null,
  password: "",
  confirmPassword: ""
};

export const useUserHooks = () => {
  const dispatch = useDispatch();

  const { user_list } = useSelector((obj) => obj);
  const { user_type_list } = useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_user_list = () => dispatch(get_user_list());
  const _get_user_type_list = () => dispatch(get_user_type_list());

  const handleSubmit = (values) => {
    
    dispatch(
      save_user(values, {
        onSuccess: () => {
          // setFormData(initialValues);
          resetFormData();
          // _get_user_list();
          // _get_user_type_list();
          // setFormData({ ...initialValues });
          formik.resetForm();
          setFormData({ ...initialValues });
          _get_user_list();
        },
        onError: (ex) => console.log(ex)
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: userValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  useEffect(() => {
    _get_user_list();
    _get_user_type_list();
  }, []);

  const handleDDChange = (e, val) => {
    
    formik.setFieldValue("usertype", e.target.value);
    formik.setFieldValue("usertypeid", val.value);
  };

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_user(data, {
          onSuccess: () => {
            setFormData(initialValues);
            resetFormData();
            _get_user_list();
            _get_user_type_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
          }
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "UserName",
        accessor: "username"
      },
      {
        Header: "User Type",
        accessor: "usertypename"
      },
      {
        Header: "Password",
        accessor: "password"
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            const { userTypeId } = cellProps.row.original;
            
            setFormData({
              ...cellProps.row.original,

              userType:
                userTypeId > 0
                  ? user_type_list.find((x) => x.id === userTypeId)
                  : null
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
          }
        })
      }
    ],
    []
  );

  const resetFormData = () => {
    //reset(initialValues);
    setFormData(initialValues);
  };

  useEffect(() => {
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  return {
    formik,
    formData,
    initialValues,
    user_list,
    user_type_list,
    column,
    resetFormData,

    actions: { handleSubmit, onGridDelete, handleDDChange },
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};
