import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { salesAction } from "../../store/actions";
import { keys } from "../../config/keys";
import { IconButton } from "@mui/material";
import { Print } from "@mui/icons-material";
import { GridAction } from "../../common/useGridActions";
import { useReactToPrint } from "react-to-print";

export const useOnlineSalesListHooks = () => {
  const dispatch = useDispatch();
  const { sales_list } = useSelector((obj) => obj);
  const [salesId, setSalesId] = useState(0);
  const [data, setData] = useState();

  const printComponentRef = useRef();

  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_sales_list = () => dispatch(salesAction.get_sales_list_online());

  useEffect(() => {
    _get_sales_list();
  }, []);

  const column = useMemo(
    () => [
      {
        Header: "Order Date",
        accessor: "orderdate",
        Cell: ({ value }) => keys.shortDate(value)
      },
      {
        Header: "Invoice #",
        accessor: "invoiceno"
      },
      {
        Header: "Customer",
        accessor: "customername"
      },
      {
        Header: "Mobile",
        accessor: "mobile"
      },
      {
        Header: "Net Total",
        accessor: "nettotal",
        Cell: ({ value }) => keys.decimalNumber(value)
      },
      {
        Header: "Products",
        accessor: "products"
      },
      {
        Header: "Payment",
        accessor: "paymentstatus"
      },
      {
        id: "print",
        Header: "",
        Cell: (cellProps) => (
          <IconButton
            size={"small"}
            color="primary"
            onClick={async () => {
              setSalesId(cellProps.row.original.id);
              // _get_sales_by_id(cellProps.row.original.id);
              // onPrintInvoice();
              // <Invoice id={cellProps.row.original.id} />;
            }}
          >
            <Print fontSize={"small"} />
          </IconButton>
        )
      },

      {
        ...GridAction({
          permission: { edit: false, delete: false }
          // onDeleteClick: (cellProps) => {
          //   setDeleteContainer({
          //     show: true,
          //     isDeleting: false,
          //     data: cellProps.row.original
          //   });
          //   // setDeleteId(cellProps.row.original.id);
          // }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const onPrintInvoice = useReactToPrint({
    content: () => printComponentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        console.log(resolve);
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    }
  });
  // const onPrintInvoice = useReactToPrint({
  //   documentTitle: "Print This Document",
  //   onBeforePrint: () => ,
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true
  //   // content: () => printComponentRef.current,
  //   // onBeforeGetContent: () => {
  //   //   return new Promise((resolve) => {
  //   //     printComponentRef.current = resolve;
  //   //     //setIsPrinting(true);
  //   //   });
  //   // },
  // });

  return {
    sales_list,
    salesId,
    column,
    data,
    printComponentRef,
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};
