import { createSlice } from "@reduxjs/toolkit";
import { USER_LIST, USER_TYPES } from "../../constTypes";

const initialState = {

  status: "idle",
  fields: [
    {
      field: "userName",
      headerName: "User Name",
      width: 100,
      align: "left",
      editable: true
    },
    {
      field: "password",
      headerName: "Password",
      width: 100
    },
    {
      field: "userType",
      headerName: "User Type",
      width: 100
    }
  ],
  userTypes: ["Super Admin", "Admin", "Manager", "Operator"],
  adminUsers: []
};

const adminSlice = createSlice({
  name: "adminSlice",
  initialState,
  reducers: {},
});

export const user_list = (state = [], action) => {
  switch (action.type) {
    case USER_LIST:
      return action.payload || [];
    default:
      return state;
  }
};

export const user_type_list = (state = [], action) => {
  switch (action.type) {
    case USER_TYPES:
      return action.payload || [];
    default:
      return state;
  }
};

export default adminSlice.reducer;
