import { SvgIcon } from "@mui/material";

const ImageIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      viewBox="0 0 14 14"
      role="img"
      focusable="false"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g transform="translate(.14285704 .14285704) scale(.28571)">
          {" "}
          <path fill="#90caf9" d="M40 13v32H8V3h22z"></path>{" "}
          <path fill="#e1f5fe" d="M38.5 14H29V4.5z"></path>{" "}
          <path fill="#1565c0" d="M21 23l-7 10h14z"></path>{" "}
          <path fill="#1976d2" d="M28 26.4L23 33h10z"></path>{" "}
          <circle cx="31.5" cy="24.5" r="1.5" fill="#1976d2"></circle>{" "}
        </g>{" "}
      </g>
    </svg>
  </SvgIcon>
);

export default ImageIcon;
