/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  get_blog_list,
  save_blog,
  deactive_blog,
  delete_blog
} from "../../store/actions/blogsAction";
import { GridAction } from "../../common/useGridActions";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { blogsValidationSchema } from "./validationSchema";

const initialValues = {
  id: 0,
  title: "",
  description: "",
  content: "",
  file: undefined,
};

export const useBlogHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialValues);


  const handleSubmit = (values) => {
console.log(values);
console.log(formData);
    dispatch(
      save_blog(values, {
        onSuccess: () => {
          setFormData({ ...initialValues });
          formik.resetForm();
        }
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: blogsValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  const handleEditorChange = (editor, name) =>
    setFormData((prev) => ({
      ...prev,
      [name]: editor
    }));

  useEffect(() => {
    console.log(formik.values)
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  useEffect(() => {
  
    if (location?.state?.data) {
      console.log(location.state.data)
      formik.setValues({
        ...location?.state?.data
      });
    }
  }, [location?.state?.data]);


  const onFileChange = (result) => {
    
    setFormData((prev) => ({
      ...prev,
      file: result,
    
    }));
  };

  return {
    formik,
    formData,
    initialValues,
    actions: { handleSubmit, handleEditorChange, onFileChange }
  };
};

export const useBlogListHooks = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { blogs_list } = useSelector((obj) => obj);

  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_blogs_list = () => dispatch(get_blog_list());

  useEffect(() => {
    _get_blogs_list();
  }, []);

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_blog(data, {
          onSuccess: () => {
            _get_blogs_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
          }
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title"
      },

      {
        Header: "Active?",
        accessor: "isactive",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isactive ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },

      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            const { id } = cellProps.row.original;

            console.log("/app/addblogs",cellProps.row.original )
           navigate(`/app/addblogs`, { state: { data: cellProps.row.original} });
          },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  return {
    initialValues,
    blogs_list,
    column,
    actions: { onGridDelete },
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};
