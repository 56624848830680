/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productValidationSchema } from "./validationSchema";
import { useFormik } from "formik";
import { get_category_list } from "../../store/actions/productCategoryAction";
import { GridAction } from "../../common/useGridActions";

import { keys } from "../../config/keys";

import { get_product_list } from "../../store/actions/productAction";
import dayjs from "dayjs";
import { get_vendor_list } from "../../store/actions/vendorAction";
import {
  delete_purchase,
  get_purchase_list,
  save_purchase,
} from "../../store/actions/purchase";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { salesAction } from "../../store/actions";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const initialValues = {
  id: 0,
  invoiceno: "",
  lastinvoiceno: "",
  description: "",
  sku: "",
  vendorid: 0,
  vendor: null,
  productid: 0,
  product: null,
  categoryid: 0,
  category: null,
  isfeatured: false,
  isactive: true,
  file: undefined,
  documentsize: 0,
  documenttype: "",
  purchase_price: 0,
  quantity: 0,
  offline_sales_price: 0,
  online_sales_price: 0,
  offline_sales_final_price: 0,
  online_sales_final_price: 0,
  sgst: 0,
  cgst: 0,
  igst: 0,
  purchasedate: dayjs(Date()),
  purchasedatestring: dayjs(Date()).format(keys.dbDateFormat),
  wefdate: dayjs(Date()).format(keys.dbDateFormat),
  product_images: [],
  offlinecgstvalue: 0,
  offlinesgstvalue: 0,
  onlinecgstvalue: 0,
  onlinesgstvalue: 0,
};

const product_image_initialvalue = {
  id: Math.random(),
  image_title: "",
  image_description: "",
  isdefault: false,
  isthumbnail: false,
  file: undefined,
  documentsize: 0,
  documenttype: "",
};

export const useProductMasterHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { product_list, category_list, vendor_list, purchase_list } =
    useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const [product_images, setProductImages] = useState(
    product_image_initialvalue
  );
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null,
  });

  const _get_product_list = () => dispatch(get_product_list());
  const _get_category_list = () => dispatch(get_category_list());
  const _get_vendor_list = () => dispatch(get_vendor_list());
  const _get_last_invoice_no = () =>
    dispatch(
      salesAction.get_last_invoiceno("purchase_master", {
        onSuccess: (res) => {
          if (res.length > 0) {
            formik.setFieldValue("invoiceno", res[0].invoiceno);
            formik.setFieldValue("lastinvoiceno", res[0].lastinvoiceno);
          }
        },
      })
    );

  const handleSubmit = (values) => {
    const { category, product, vendor, purchasedate, ...others } = values;

    const data = {
      ...others,
      productname: product.label,
      categoryid: category.id,
    };
    dispatch(
      save_purchase(data, {
        onSuccess: () => {
          setFormData({ ...initialValues });
          _get_last_invoice_no();
          formik.resetForm();
          toast.success("Purchase saved successfully.");
          navigate("/app/purchase");
        },
        onError: (ex) => toast.error(ex),
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: productValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    _get_product_list();
    _get_category_list();
    _get_vendor_list();
    _get_last_invoice_no();
  }, []);

  const handleDDChange = (e, val) => {
    formik.setFieldValue("category", val);
    formik.setFieldValue("categoryid", val === null ? 0 : val?.id);
  };

  const handleVendorDDChange = (e, val) => {
    formik.setFieldValue("vendor", val);
    formik.setFieldValue("vendorid", val === null ? 0 : val?.id);
  };

  const handleProductChange = (e, val) => {
    if (typeof val === "string") {
      formik.setFieldValue("product", val);
      formik.setFieldValue("productid", 0);
    } else {
      formik.setFieldValue("product", val);
      formik.setFieldValue("productid", val?.id);

      formik.setFieldValue(
        "category",
        val?.id > 0 ? category_list.find((x) => x.id === val.categoryid) : null
      );
      formik.setFieldValue("sku", val?.id > 0 ? val?.sku : "");
    }
  };

  useEffect(() => {
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  const calculatePrice = () => {
    const offlineBasePrice = formik.values.offline_sales_price;
    const onlineBasePrice = formik.values.online_sales_price;
    const cgst = formik.values.cgst;
    const sgst = formik.values.sgst;
    const offlinecgstvalue = (offlineBasePrice * cgst) / 100;
    const offlinesgstvalue = (offlineBasePrice * sgst) / 100;
    const onlinecgstvalue = (onlineBasePrice * cgst) / 100;
    const onlinesgstvalue = (onlineBasePrice * sgst) / 100;

    const offline_sales_final_price =
      offlineBasePrice +
      (offlineBasePrice * cgst) / 100 +
      (offlineBasePrice * sgst) / 100;
    const online_sales_final_price =
      onlineBasePrice +
      (onlineBasePrice * cgst) / 100 +
      (onlineBasePrice * sgst) / 100;

    formik.setValues({
      ...formik.values,
      offline_sales_final_price,
      online_sales_final_price,
      offlinecgstvalue,
      offlinesgstvalue,
      onlinecgstvalue,
      onlinesgstvalue,
    });
  };

  useMemo(
    () => calculatePrice(),
    [
      formik.values.offline_sales_price,
      formik.values.online_sales_price,
      formik.values.cgst,
      formik.values.sgst,
    ]
  );

  const onFileChange = (result) => {
    // formik.setFieldValue("file", result);
    // formik.setFieldValue("documentsize", result?.size);
    // formik.setFieldValue("documenttype", result?.type);
    // setFormData((prev) => ({
    //   ...prev,
    //   file: result,
    //   documentsize: result?.size,
    //   documenttype: result?.type
    // }));
    setProductImages((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type,
    }));
  };

  const onDateChange = (dateValue) => {
    // setFormData((prev) => ({
    //   ...prev,
    //   purchasedate: dateValue,
    //   purchasedatestring: dayjs(dateValue).format("YYYY-MM-DD")
    // }));
    formik.setValues({
      ...formik.values,
      purchasedate: dateValue,
      purchasedatestring: dayjs(dateValue).format("YYYY-MM-DD"),
    });
  };

  const resetForm = () => setFormData({ ...initialValues });

  const handleChangeImageData = (e) => {
    setProductImages((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeImageChecked = (e) => {
    setProductImages((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const onAddClick = () => {
    const p_images = [...formData.product_images];
    p_images.push({ ...product_images, id: Math.random() });
    setFormData((prev) => ({
      ...prev,
      product_images: p_images,
    }));

    setProductImages({ ...product_image_initialvalue });
  };

  const onGridDelete = () => {
    const { data } = deleteContainer;
    const t = formData.product_images.filter((x) => x.id !== data.id);
    setFormData((prev) => ({
      ...prev,
      product_images: [...t],
    }));

    setDeleteContainer({
      show: false,
      isDeleting: false,
      data,
    });
  };

  const column = useMemo(
    () => [
      {
        Header: "Image Title",
        accessor: "image_title",
      },
      {
        Header: "Description",
        accessor: "image_description",
      },
      {
        Header: "Default",
        accessor: "isdefault",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isdefault ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        },
      },
      {
        Header: "Thumbnail?",
        accessor: "isthumbnail",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isthumbnail ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        },
      },
      {
        Header: "type",
        accessor: "documenttype",
      },
      {
        id: "p_image",
        Header: "",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle",
        },
        Cell: (cellProps) => {
          return cellProps.row.original.documenttype.includes("video") ? (
            <video width="30" controls>
              <source
                src={cellProps.row.original.file.base64}
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              src={cellProps.row.original.file.base64}
              alt="p_image"
              width={30}
            />
          );
        },
      },
      {
        ...GridAction({
          permission: { edit: false, delete: true },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original,
            });
            // setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return {
    formik,
    formData,
    initialValues,
    product_list,
    category_list,
    vendor_list,
    purchase_list,
    product_images,
    column,
    actions: {
      handleSubmit,
      handleDDChange,
      onFileChange,
      resetForm,
      onDateChange,
      handleProductChange,
      handleVendorDDChange,
      handleChangeImageData,
      handleChangeImageChecked,
      onAddClick,
      onGridDelete,
    },
    delete: {
      deleteContainer,
      setDeleteContainer,
    },
  };
};

export const usePurchaseMasterListHooks = () => {
  const dispatch = useDispatch();

  const { purchase_list } = useSelector((obj) => obj);

  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null,
  });

  const _get_purchase_list = () => dispatch(get_purchase_list());

  useEffect(() => {
    _get_purchase_list();
  }, []);

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_purchase(data, {
          onSuccess: () => {
            _get_purchase_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined,
            });
          },
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Purchase Date",
        accessor: "purchasedate",
        Cell: ({ value }) => keys.shortDate(value),
      },
      {
        Header: "Invoice #",
        accessor: "invoiceno",
      },
      {
        Header: "Vendor",
        accessor: "vendor",
      },
      {
        Header: "Product",
        accessor: "productname",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },

      {
        ...GridAction({
          permission: { edit: false, delete: true },
          // onEditClick: (cellProps) => {
          //   const { categoryid } = cellProps.row.original;
          //   setFormData({
          //     ...cellProps.row.original,
          //     file: {
          //       name:
          //         cellProps.row.original.productimage !== null
          //           ? cellProps.row.original.productimage.split("/").pop()
          //           : ""
          //     },
          //     isfeatured: Boolean(cellProps.row.original.isfeatured),
          //     isactive: Boolean(cellProps.row.original.isactive),
          //     category:
          //       categoryid > 0
          //         ? category_list.find((x) => x.id === categoryid)
          //         : null
          //   });

          // },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original,
            });
            // setDeleteId(cellProps.row.original.id);
          },
        }),
      },
    ],
    // eslint-disable-next-line
    []
  );

  return {
    purchase_list,
    column,
    actions: {
      onGridDelete,
    },
    delete: {
      deleteContainer,
      setDeleteContainer,
    },
  };
};
