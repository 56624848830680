import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fade,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";

import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  one_column_grid,
  textFieldProps,
  two_column_grid
} from "../../common/controlProps";
import { useProductMasterHooks } from "./useProductMasterHooks";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import {
  Add,
  AddShoppingCart,
  Cancel,
  Delete,
  DensitySmall,
  FormatListNumbered,
  PlusOne,
  Save
} from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";
import FileToBase64 from "../../components/FileBaseTo64";
import DeleteModal from "../../components/DeleteModal";
import { useState } from "react";
import { purple } from "@mui/material/colors";

const ProductMaster = () => {
  const {
    formik,
    product_list,
    category_list,
    actions,
    column,
    formData,
    product_images,
    pi_column,
    attribute_master_value_list,
    selectedattribute,
    selectedattributevalue,
    style,
    open,
    openSpecification,
    product_specifications,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    },
    deleteProduct: { deleteProductContainer, setDeleteProductContainer }
  } = useProductMasterHooks();

  return (
    <MainCard title="Product Master">
      <Grid container spacing={1}>
        <Grid item xl={12} xs={12} sm={12} md={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="SKU"
                  name="sku"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.sku}
                  error={formik.errors.sku}
                  helperText={formik.touched.sku && formik.errors?.sku}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Product Name"
                  name="productname"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.productname}
                  error={formik.errors.productname}
                  helperText={
                    formik.touched.productname && formik.errors?.productname
                  }
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="category"
                  size="small"
                  options={category_list}
                  getOptionLabel={(option) => option.label || null}
                  value={formik.values.category}
                  onChange={actions.handleDDChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Category"
                      error={formik.errors.category}
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  helperText={"(Press Enter for new line.)"}
                />
              </Grid>
              {/* <Grid item {...four_column_grid}>
                <TextField
                  disabled
                  label={`File name`}
                  helperText={"(Max size 150 KB)"}
                  InputLabelProps={{ shrink: true }}
                  value={(formData.file && formData.file?.name) || ""}
                  {...textFieldProps}
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FileToBase64 onChange={actions.onFileChange} />
              </Grid> */}
              <Grid
                item
                {...two_column_grid}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isfeatured"
                      color="success"
                      size="small"
                      checked={formik.values.isfeatured}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Featured?"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                {...two_column_grid}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isactive"
                      color="success"
                      size="small"
                      checked={formik.values.isactive}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active?"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                xl={5}
                md={5}
                sm={12}
                display={"flex"}
                alignItems={"center"}
              >
                <Button
                  {...buttonFieldProps}
                  className="custom-btn-page-layout custom-btn-purple"
                  type="button"
                  startIcon={<AddShoppingCart />}
                  onClick={actions.handleOpen}
                >
                  {`Frequently bought together products ${`(${formData?.frequent_products?.length})`}`}
                </Button>
              </Grid>
              <Grid
                item
                xl={3}
                md={3}
                sm={12}
                display={"flex"}
                alignItems={"center"}
              >
                <Button
                  {...buttonFieldProps}
                  className="custom-btn-page-layout custom-btn-warning"
                  type="button"
                  startIcon={<FormatListNumbered />}
                  onClick={actions.handleSpecificationOpen}
                >
                  {`Add Specifications (${formData?.product_specifications?.length})`}
                </Button>
              </Grid>
            </Grid>
            <Divider textAlign="left" className="mt-2">
              <Chip label="Product Attributes" size="small" color="primary" />
            </Divider>
            <Grid container spacing={2} marginTop={1}>
              <Grid item md={2} lg={2} sm={12}>
                <Autocomplete
                  name="attribute"
                  size="small"
                  options={attribute_master_value_list}
                  getOptionLabel={(option) => option?.label}
                  value={selectedattribute}
                  onChange={actions.handleAttributeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Attribute"
                      placeholder="Attribute"
                      error={formik.errors.attribute}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} lg={6} sm={12}>
                <Autocomplete
                  multiple
                  limitTags={3}
                  name="attribute"
                  size="small"
                  options={selectedattribute?.attributevalues || []}
                  getOptionLabel={(option) => option.label || ""}
                  value={selectedattributevalue || []}
                  onChange={actions.handleAttributeValueChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      label="Attribute"
                      placeholder="Attribute"
                      error={formik.errors.attribute}
                    />
                  )}
                />
              </Grid>
              <Grid item {...one_column_grid}>
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  size="medium"
                  className="custom-btn-page-layout"
                  onClick={actions.onAddAttributesClick}
                  disabled={
                    selectedattributevalue === null ||
                    selectedattributevalue.length <= 0
                  }
                >
                  <Add fontSize="medium" />
                </IconButton>
              </Grid>
              {formik?.values?.attribute_values.length > 0 && (
                <Grid item col={12} md={12} sm={12}>
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th>{`Attribute`}</th>
                        <th>{`Attribute Values`}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik?.values?.attribute_values?.map((x) => (
                        <tr key={Math.random()}>
                          <td>{x.attribute}</td>
                          <td>{x.attributevalue}</td>
                          <td>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => actions.onDeleteAttributeClick(x)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
              )}
            </Grid>
            <Divider textAlign="left" className="mt-2">
              <Chip
                label="Product Images/videos"
                size="small"
                color="primary"
              />
            </Divider>

            <Grid container spacing={2} marginTop={1}>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  label="Image Title"
                  name="image_title"
                  onChange={actions.handleChangeImageData}
                  value={product_images.image_title}
                  //error={formik.errors.purchase_price}
                  // helperText={
                  //   formik.touched.purchase_price &&
                  //   formik.errors?.purchase_price
                  // }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  label="Image Description"
                  name="image_description"
                  onChange={actions.handleChangeImageData}
                  value={product_images.image_description}
                  // error={formik.errors.purchase_price}
                  // helperText={
                  //   formik.touched.purchase_price &&
                  //   formik.errors?.purchase_price
                  // }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isdefault"
                      color="success"
                      size="small"
                      onChange={actions.handleChangeImageChecked}
                      checked={product_images.isdefault}
                    />
                  }
                  label="Default?"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isthumbnail"
                      color="success"
                      size="small"
                      onChange={actions.handleChangeImageChecked}
                      checked={product_images.isthumbnail}
                    />
                  }
                  label="Thumbnail?"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item {...four_column_grid}>
                {product_images.file && product_images.file?.name && (
                  <FormHelperText>{product_images.file?.name} </FormHelperText>
                )}
                <FileToBase64
                  onChange={actions.onPIFileChange}
                  buttontitle="Upload Image/Video File"
                />
              </Grid>
              <Grid item {...one_column_grid}>
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  size="medium"
                  className="custom-btn-page-layout"
                  onClick={actions.onAddClick}
                  disabled={product_images.title === "" || !product_images.file}
                >
                  <Add fontSize="medium" />
                </IconButton>
              </Grid>
              {formData?.product_images &&
                formData?.product_images?.length > 0 && (
                  <Grid item xl={12} md={12} sm={12} className="border-1">
                    <div className="border border-secondary-subtle mt-2">
                      <TableContainer
                        columns={pi_column}
                        data={formData.product_images}
                        isGlobalFilter={false}
                        exports={{ pdf: false, print: false }}
                        showColumnFilters={false}
                        isAddUserList={false}
                        tableClass={"table-sm"}
                        divClass={"table-responsive"}
                        showPagination={false}
                        initialState={{ pageSize: 10, pageIndex: 0 }}
                      />
                    </div>
                  </Grid>
                )}
            </Grid>
            <DeleteModal
              deleteModal={deleteProductContainer.show}
              handleDeleteRequest={actions.onGridProductDelete}
              setDeleteModal={(show) =>
                setDeleteProductContainer((prev) => ({ ...prev, show }))
              }
              deleteLoading={deleteProductContainer.isDeleting}
              centered={true}
            />
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="submit"
                  color="success"
                  startIcon={<Save />}
                >
                  Save
                </StyledButton>
              </Grid>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="button"
                  color="error"
                  startIcon={<Cancel />}
                  onClick={actions.resetForm}
                >
                  {`Cancel`}
                </StyledButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      {/* <Grid item xl={12} md={12} sm={12} className="border-1">
        <div className="border border-secondary-subtle mt-2">
          <TableContainer
            columns={column}
            data={product_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      </Grid>
      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      /> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={actions.handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xl={12} xs={12} sm={12} md={12}>
                <Typography
                  variant="h4"
                  color={purple[300]}
                >{`Products frequently bought together `}</Typography>
                <Divider
                  className="mt-2"
                  style={{
                    borderColor: purple[500],
                    borderWidth: "1px",
                    opacity: "0.8"
                  }}
                />
              </Grid>

              <Grid item xl={12} xs={12} sm={12} md={12}>
                <Autocomplete
                  name="products"
                  size="small"
                  multiple
                  options={product_list}
                  getOptionLabel={(option) => option?.label}
                  value={formik.values.frequent_products}
                  onChange={actions.onFrequentProductsAdd}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Products"
                      placeholder="Frequently bought together"
                      // error={formik.errors.attribute}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={4} xs={12} sm={12} md={4}>
                <Button
                  {...buttonFieldProps}
                  className="custom-btn-page-layout custom-btn-deeppurple"
                  type="button"
                  startIcon={<AddShoppingCart />}
                  onClick={actions.handleClose}
                >
                  {"Add/Update Frequent products"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSpecification}
        onClose={actions.handleSpecificationClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={openSpecification}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xl={12} xs={12} sm={12} md={12}>
                <Typography
                  variant="h4"
                  color={purple[300]}
                >{`Specifications`}</Typography>
                <Divider
                  className="mt-2"
                  style={{
                    borderColor: purple[500],
                    borderWidth: "1px",
                    opacity: "0.8"
                  }}
                />
              </Grid>
              <Grid item xl={12} xs={12} sm={12} md={12}>
                <TextField
                  {...textFieldProps}
                  label="Specification Title/Description"
                  name="specification_description"
                  onChange={actions.handleProductSpecificationChange}
                  value={formData.specification_description}
                />
              </Grid>
              <Grid item xl={4} xs={12} sm={12} md={4}>
                <TextField
                  {...textFieldProps}
                  label="Specification Type"
                  name="specification_type"
                  onChange={actions.handleSpecificationChange}
                  value={product_specifications.specification_type}
                />
              </Grid>
              <Grid item xl={6} xs={12} sm={12} md={6}>
                <TextField
                  {...textFieldProps}
                  label="Specification Value"
                  name="specification_value"
                  onChange={actions.handleSpecificationChange}
                  value={product_specifications.specification_value}
                />
              </Grid>
              <Grid item xl={2} xs={12} sm={12} md={2}>
                <IconButton
                  aria-label="add specification"
                  size="medium"
                  className="custom-btn-page-layout custom-btn-deeppurple"
                  onClick={actions.onAddSpecificationClick}
                  //disabled={product_images.title === "" || !product_images.file}
                >
                  <Add fontSize="medium" />
                </IconButton>
              </Grid>
              {formData.product_specifications.length > 0 && (
                <Grid item col={12} md={12} sm={12}>
                  <table className="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th>{`Specification Type`}</th>
                        <th>{`Specification Value`}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.product_specifications
                        ?.filter((x) => x.isdelete === 0)
                        .map((x) => (
                          <tr key={x.id}>
                            <td>{x.specification_type}</td>
                            <td>{x.specification_value}</td>
                            <td>
                              <IconButton
                                color="error"
                                size="small"
                                onClick={() =>
                                  actions.onDeleteSpecificationClick(x)
                                }
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Grid>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </MainCard>
  );
};

export default ProductMaster;
