import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";
import {
  ProductCategory,
  ProductMaster,
  VendorMaster,
  PurchaseMaster,
  SalesMaster,
  SalesList,
  PurchaseList,
  ProductList,
  OnlineSalesList,
  BlogList
} from "../pages";
import { useAuth } from "../App";
import { Navigate, useLocation, useNavigate } from "react-router";
import Blogs from "../pages/Blogs/Blogs";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("../pages/dashboard")));

// render - sample page
const SamplePage = Loadable(
  lazy(() => import("../pages/extra-pages/SamplePage"))
);
const UserMaster = Loadable(lazy(() => import("../pages/UserMaster")));

// render - utilities
const Typography = Loadable(
  lazy(() => import("../pages/components-overview/Typography"))
);
const Color = Loadable(
  lazy(() => import("../pages/components-overview/Color"))
);
const Shadow = Loadable(
  lazy(() => import("../pages/components-overview/Shadow"))
);
const AntIcons = Loadable(
  lazy(() => import("../pages/components-overview/AntIcons"))
);

// ==============================|| MAIN ROUTING ||============================== //

export const RequireAuth = ({ children }) => {
  let auth = useAuth();
  let location = useLocation();
  const navi = useNavigate();
  // const { promptLogout, handleStillHere, remaining } = useIdleTimerHooks();
  const logout = () => {
    auth.signout(() => {
      navi("/");
    });
  };
  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  // else if (promptLogout) {
  //   return (
  //     <Modal open={promptLogout} onClose={logout} center>
  //       <p>
  //         <h3>{`Are you still here`}</h3>
  //       </p>
  //       <p>
  //         <h3>{`Logging out in ${remaining} seconds`}</h3>
  //       </p>
  //       <br />
  //       <p>
  //         <button className="btn btn-dark btn-sm" onClick={handleStillHere}>
  //           {`I am still here`}
  //         </button>
  //       </p>
  //     </Modal>
  //   );
  // }

  return children;
};

const MainRoutes = {
  path: "/app",
  element: (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  ),
  children: [
    {
      path: "/app/",
      element: <DashboardDefault />
    },
    {
      path: "/app/usermaster",
      element: <UserMaster />
    },
    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    {
      path: "/app/categorymaster",
      element: <ProductCategory />
    },
    {
      path: "/app/vendormaster",
      element: <VendorMaster />
    },
    {
      path: "/app/productlist",
      element: <ProductList />
    },
    {
      path: "/app/productmaster",
      element: <ProductMaster />
    },
    {
      path: "/app/purchase",
      element: <PurchaseList />
    },
    {
      path: "/app/purchase/add",
      element: <PurchaseMaster />
    },
    {
      path: "/app/sales",
      element: <SalesList />
    },
    {
      path: "/app/salesorder",
      element: <SalesMaster />
    },
    {
      path: "/app/salesonline",
      element: <OnlineSalesList />
    },
    {
      path: "/app/blogs",
      element: <BlogList/>
    },
    {
      path: "/app/addblogs",
      element: <Blogs/>
    }
  ]
};

export const OperationRoutes = {
  path: "/app",
  element: (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  ),
  children: [
    {
      path: "/app/",
      element: <DashboardDefault />
    },
    {
      path: "/app/purchase",
      element: <PurchaseList />
    },
    {
      path: "/app/purchase/add",
      element: <PurchaseMaster />
    },
    {
      path: "/app/sales",
      element: <SalesList />
    },
    {
      path: "/app/salesorder",
      element: <SalesMaster />
    }
  ]
};

export const SalesRoutes = {
  path: "/app",
  element: (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  ),
  children: [
    {
      path: "/app/",
      element: <DashboardDefault />
    },
    {
      path: "/app/sales",
      element: <SalesList />
    },
    {
      path: "/app/salesorder",
      element: <SalesMaster />
    }
  ]
};
export default MainRoutes;
