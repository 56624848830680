import {
  Autocomplete,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  createFilterOptions
} from "@mui/material";

import MainCard from "../../components/MainCard";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  one_column_grid,
  textFieldProps,
  two_column_grid
} from "../../common/controlProps";
import { useProductMasterHooks } from "./usePurchaseMasterHooks";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import { Add, Cancel, Save } from "@mui/icons-material";

import FileToBase64 from "../../components/FileBaseTo64";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import TableContainer from "../../components/TableComponent/TableContainer";
import DeleteModal from "../../components/DeleteModal";

const PurchaseMaster = () => {
  const {
    formData,
    formik,
    product_list,
    category_list,
    vendor_list,
    actions,
    column,
    product_images,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useProductMasterHooks();

  const filter = createFilterOptions();

  return (
    <MainCard title="Purchase Master">
      <Grid container spacing={1}>
        <Grid item xl={12} xs={12} sm={12} md={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item {...two_column_grid}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Purchase Date"
                    format={keys.shortDateFormat}
                    slotProps={{
                      textField: {
                        ...textFieldProps
                      }
                    }}
                    onChange={actions.onDateChange}
                    value={formik?.values?.purchasedate}
                    clearable
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Invoice #"
                  name="invoiceno"
                  onChange={formik.handleChange}
                  value={formik.values.invoiceno}
                  error={formik.errors.invoiceno}
                  helperText={
                    formik.touched.invoiceno && formik.errors?.invoiceno
                  }
                />
                <FormHelperText>{`Last Invoice #: ${formik.values.lastinvoiceno}`}</FormHelperText>
              </Grid>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="vendor"
                  size="small"
                  freeSolo
                  options={vendor_list}
                  getOptionLabel={(option) => option.label}
                  onChange={actions.handleVendorDDChange}
                  value={formik.values.vendor}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Vendor"
                      error={formik.errors.vendor}
                    />
                  )}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="product"
                  size="small"
                  freeSolo
                  options={product_list}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return `${option.label}`;
                    }
                    return option.label;
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.label
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        id: 0,
                        inputValue: `Add "${inputValue}"`,
                        label: inputValue
                      });
                    }

                    return filtered;
                  }}
                  onChange={actions.handleProductChange}
                  value={formik.values.product}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Product"
                      error={formik.errors.product}
                    />
                  )}
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="SKU"
                  name="sku"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.sku}
                  error={formik.errors.sku}
                  disabled={formik.values.productid > 0}
                  helperText={formik.touched.sku && formik.errors?.sku}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="category"
                  size="small"
                  options={category_list}
                  getOptionLabel={(option) => option.label || null}
                  value={formik.values.category}
                  onChange={actions.handleDDChange}
                  disabled={formik.values.productid > 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Category"
                      error={formik.errors.category}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item {...four_column_grid}>
                <TextField
                  disabled
                  label={`File name`}
                  helperText={"(Max size 150 KB)"}
                  InputLabelProps={{ shrink: true }}
                  //value={(formData.file && formData.file?.name) || ""}
                  {...textFieldProps}
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FileToBase64 onChange={actions.onFileChange} />
              </Grid> */}
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Quantity"
                  name="quantity"
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                  error={formik.errors.quantity}
                  helperText={
                    formik.touched.quantity && formik.errors?.quantity
                  }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Purchase Price"
                  name="purchase_price"
                  onChange={formik.handleChange}
                  value={formik.values.purchase_price}
                  error={formik.errors.purchase_price}
                  helperText={
                    formik.touched.purchase_price &&
                    formik.errors?.purchase_price
                  }
                />
              </Grid>
              <Grid item md={12} lg={12} sm={12}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  helperText={"(Press Enter for new line)"}
                />
              </Grid>
              {/* <Grid item {...two_column_grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isfeatured"
                      color="success"
                      size="small"
                      checked={formik.values.isfeatured}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Featured?"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isactive"
                      color="success"
                      size="small"
                      checked={formik.values.isactive}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Is Active?"
                  labelPlacement="start"
                />
              </Grid> */}
            </Grid>
            <Divider textAlign="left" className="mt-2">
              <Chip label="Pricing Section" size="small" color="primary" />
            </Divider>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Offline Sales Price"
                  name="offline_sales_price"
                  onChange={formik.handleChange}
                  value={formik.values.offline_sales_price}
                  error={formik.errors.offline_sales_price}
                  helperText={
                    formik.touched.offline_sales_price &&
                    formik.errors?.offline_sales_price
                  }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Online Sales Price"
                  name="online_sales_price"
                  onChange={formik.handleChange}
                  value={formik.values.online_sales_price}
                  error={formik.errors.online_sales_price}
                  helperText={
                    formik.touched.online_sales_price &&
                    formik.errors?.online_sales_price
                  }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="SGST (in %)"
                  name="sgst"
                  onChange={formik.handleChange}
                  value={formik.values.sgst}
                  error={formik.errors.sgst}
                  helperText={formik.touched.sgst && formik.errors?.sgst}
                />
              </Grid>

              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="CGST (in %)"
                  name="cgst"
                  onChange={formik.handleChange}
                  value={formik.values.cgst}
                  error={formik.errors.cgst}
                  helperText={formik.touched.cgst && formik.errors?.cgst}
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Offine Sales (inc taxes)"
                  name="offline_sales_final_price"
                  onChange={formik.handleChange}
                  value={formik.values.offline_sales_final_price}
                  error={formik.errors.offline_sales_final_price}
                  helperText={
                    formik.touched.offline_sales_final_price &&
                    formik.errors?.offline_sales_final_price
                  }
                />
                <FormHelperText className="fw-bold text-primary">{`Total Tax: ${
                  formik.values.offlinecgstvalue +
                  formik.values.offlinesgstvalue
                }`}</FormHelperText>
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Online Sales (inc taxes)"
                  name="online_sales_final_price"
                  onChange={formik.handleChange}
                  value={formik.values.online_sales_final_price}
                  error={formik.errors.online_sales_final_price}
                  helperText={
                    formik.touched.online_sales_final_price &&
                    formik.errors?.online_sales_final_price
                  }
                />
                <FormHelperText className="fw-bold text-primary">{`Total Tax: ${
                  formik.values.onlinecgstvalue + formik.values.onlinesgstvalue
                }`}</FormHelperText>
              </Grid>
            </Grid>
            <Divider textAlign="left" className="mt-2">
              <Chip
                label="Product Images/videos"
                size="small"
                color="primary"
              />
            </Divider>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  label="Image Title"
                  name="image_title"
                  onChange={actions.handleChangeImageData}
                  value={product_images.image_title}
                  //error={formik.errors.purchase_price}
                  // helperText={
                  //   formik.touched.purchase_price &&
                  //   formik.errors?.purchase_price
                  // }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  label="Image Description"
                  name="image_description"
                  onChange={actions.handleChangeImageData}
                  value={product_images.image_description}
                  // error={formik.errors.purchase_price}
                  // helperText={
                  //   formik.touched.purchase_price &&
                  //   formik.errors?.purchase_price
                  // }
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isdefault"
                      color="success"
                      size="small"
                      onChange={actions.handleChangeImageChecked}
                      checked={product_images.isdefault}
                    />
                  }
                  label="Default?"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item {...two_column_grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isthumbnail"
                      color="success"
                      size="small"
                      onChange={actions.handleChangeImageChecked}
                      checked={product_images.isthumbnail}
                    />
                  }
                  label="Thumbnail?"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item {...four_column_grid}>
                {product_images.file && product_images.file?.name && (
                  <FormHelperText>{product_images.file?.name} </FormHelperText>
                )}
                <FileToBase64
                  onChange={actions.onFileChange}
                  buttontitle="Upload Image/Video File"
                />
              </Grid>
              <Grid item {...one_column_grid}>
                <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  size="medium"
                  className="custom-btn-page-layout"
                  onClick={actions.onAddClick}
                  disabled={product_images.title === "" || !product_images.file}
                >
                  <Add fontSize="medium" />
                </IconButton>
              </Grid>
              {formData.product_images.length > 0 && (
                <Grid item xl={12} md={12} sm={12} className="border-1">
                  <div className="border border-secondary-subtle mt-2">
                    <TableContainer
                      columns={column}
                      data={formData.product_images}
                      isGlobalFilter={false}
                      exports={{ pdf: false, print: false }}
                      showColumnFilters={false}
                      isAddUserList={false}
                      tableClass={"table-sm"}
                      divClass={"table-responsive"}
                      showPagination={false}
                      initialState={{ pageSize: 10, pageIndex: 0 }}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="submit"
                  color="success"
                  startIcon={<Save />}
                >
                  Save
                </StyledButton>
              </Grid>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="button"
                  color="error"
                  startIcon={<Cancel />}
                  onClick={actions.resetForm}
                >
                  {`Cancel`}
                </StyledButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
      {/* <Grid item xl={12} md={12} sm={12} className="border-1">
        <div className="border border-secondary-subtle mt-2">
          <TableContainer
            columns={column}
            data={purchase_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      </Grid>
      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      /> */}
    </MainCard>
  );
};

export default PurchaseMaster;
