import { Grid, TextField, MenuItem } from "@mui/material";

import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  textFieldProps
} from "../../common/controlProps";
import { useUserHooks } from "./useUserHooks";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import { Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";
import DeleteModal from "../../components/DeleteModal";

const UserMaster = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    actions,
    formik,
    user_list,
    user_type_list,
    column,
    formData,
    resetFormData,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useUserHooks();

  return (
      <MainCard title="User Master">
        <Grid container spacing={1}>
          <Grid item xl={12} xs={12} sm={12} md={12}>
            <Form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item {...four_column_grid}>
                  <TextField
                    {...textFieldProps}
                    type="text"
                    label="User Name"
                    name="username"
                    InputLabelProps={{shrink: true}}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    error={
                      !!formik.touched.username && !!formik.errors.username
                    }
                    placeholder="Please Enter Your Name."
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </Grid>
                <Grid item {...four_column_grid}>
                  <TextField
                    {...textFieldProps}
                    type="Password"
                    label="Password"
                    name="password"
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.password && !!formik.errors.password
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item {...four_column_grid}>
                  <TextField
                    {...textFieldProps}
                    type="Password"
                    label="Confirm Password"
                    placeholder="example@123"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.confirmPassword &&
                      !!formik.errors.confirmPassword
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item {...four_column_grid}>
                  <TextField
                    {...textFieldProps}
                    select
                    label="User Type"
                    name="usertypeid"
                    defaultValue=""
                    value={formik.values.usertypeid}
                    sx={{ gridColumn: "span 1" }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      !!formik.touched.usertypeid && !!formik.errors.usertypeid
                    }
                    helperText={
                      formik.touched.usertypeid && formik.errors.usertypeid
                    }
                  >
                    {user_type_list.map((userType, key) => (
                      <MenuItem key={userType.id} value={userType.id}>
                        {userType.usertypename}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={1}>
                <Grid item {...button_grid}>
                  <StyledButton
                    {...buttonFieldProps}
                    type="submit"
                    color="success"
                    startIcon={<Save />}
                  >
                    Save
                  </StyledButton>
                </Grid>
                <Grid item {...button_grid}>
                  <StyledButton
                    {...buttonFieldProps}
                    type="button"
                    color="error"
                    startIcon={<Cancel />}
                  >
                    {`Cancel`}
                  </StyledButton>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} sm={12} className="border-1">
          <div className="border border-secondary-subtle mt-2">
            <TableContainer
              columns={column}
              data={user_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        </Grid>
        <DeleteModal
          deleteModal={show}
          handleDeleteRequest={actions.onGridDelete}
          setDeleteModal={(show) =>
            setDeleteContainer((prev) => ({ ...prev, show }))
          }
          deleteLoading={isDeleting}
          centered={true}
        />
      </MainCard>
  );
};

export default UserMaster;
