import { CUSTOMER_LIST } from "../constTypes";

export const customer_list = (state = [], action) => {
  switch (action.type) {
    case CUSTOMER_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
