import { Grid } from "@mui/material";

import MainCard from "../../components/MainCard";

import { buttonFieldProps } from "../../common/controlProps";

import StyledButton from "../../components/Button";
import { AddShoppingCart } from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";

import DeleteModal from "../../components/DeleteModal";

import { useNavigate } from "react-router";
import Invoice from "./Invoice";
import { useOnlineSalesListHooks } from "./useOnlineSalesListHooks";
import OnlineSalesInvoice from "./OnlineSalesInvoice";

const OnlineSalesList = () => {
  const navigate = useNavigate();
  const {
    sales_list,
    salesId,
    data,
    column,
    actions,
    printComponentRef,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useOnlineSalesListHooks();

  return (
    <MainCard title="Sales Order List">
      
      <Grid container spacing={1} marginTop={1}>
        <Grid item xl={12} md={12} sm={12} className="border-1">
          <div className="border border-secondary-subtle mt-2">
            <TableContainer
              columns={column}
              data={sales_list}
              isGlobalFilter={false}
              showColumnFilters={false}
              isAddUserList={false}
              showFooter={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        </Grid>
      </Grid>

      <DeleteModal
        deleteModal={show}
        //handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
      <div className="d-none">
        <OnlineSalesInvoice id={salesId} ref={printComponentRef} data={data}/>
      </div>
    </MainCard>
  );
};

export default OnlineSalesList;
