/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendorValidationSchema } from "./validationSchema";
import { useFormik } from "formik";
import {
  get_vendor_list,
  save_vendor,
  delete_vendor
} from "../../store/actions/vendorAction";
import { GridAction } from "../../common/useGridActions";

const initialValues = {
  id: 0,
  company: "",
  tan: "",
  gstno: "",
  address: "",
  stateid: "",
  cityid: "",
  pincode: "",
  contact_person: "",
  contact_phone: "",
  contact_email: "",
  description:""
};

export const useVendorMasterHooks = () => {
  const dispatch = useDispatch();

  const { vendor_list } = useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_vendor_list = () => dispatch(get_vendor_list());
  const handleSubmit = (values) => {
  
    dispatch(
      save_vendor(values, {
        onSuccess: () => {
          _get_vendor_list();
          setFormData({ ...initialValues });
          formik.resetForm();
        }
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: vendorValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  useEffect(() => {
    _get_vendor_list();
  }, []);


  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_vendor(data, {
          onSuccess: () => {
            _get_vendor_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
          }
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Company",
        accessor: "company"
      },
      {
        Header: "TAN",
        accessor: "tan"
      },
      {
        Header: "GSTNO",
        accessor: "gstno"
      },
      {
        Header: "Address",
        accessor: "address"
      },
      {
        Header: "Contact Person",
        accessor: "contact_person"
      },
      {
        Header: "Contact Phone ",
        accessor: "contact_phone "
      },
      {
        Header: "Contact Email",
        accessor: "contact_email"
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            setFormData({
              ...cellProps.row.original
            });
          },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  const resetForm = () => setFormData({ ...initialValues });

  return {
    formik,
    formData,
    initialValues,
    vendor_list,
    column,
    actions: { handleSubmit, onGridDelete, resetForm },
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};
