import { keys } from '../../config/keys';
import axios from '../../common/axios';
import { _dispatchPayload } from './dispatchPayload';
import { CUSTOMER_LIST } from '../constTypes';

export const get_customer_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/customer`)
    .then((res) => {
      
      dispatch(_dispatchPayload(CUSTOMER_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};