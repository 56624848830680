/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryValidationSchema } from "./validationSchema";
import { useFormik } from "formik";
import {
  delete_category,
  get_category_list,
  save_category
} from "../../store/actions/productCategoryAction";
import { GridAction } from "../../common/useGridActions";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Circle,
  Image
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { keys } from "../../config/keys";
import { ImageIcon } from "../../Icons";

const initialValues = {
  id: 0,
  category: "",
  parentcategoryid: 0,
  parentcategory: null,
  isfeatured: false,
  displayonweb: true,
  file: undefined,
  documentsize: 0,
  documenttype: "",
  tagline: "",
  description: ""
};

export const useCategoryHooks = () => {
  const dispatch = useDispatch();

  const { category_list } = useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_category_list = () => dispatch(get_category_list());

  const handleSubmit = (values) => {
    dispatch(
      save_category(values, {
        onSuccess: () => {
          _get_category_list();
          setFormData({ ...initialValues });
          formik.resetForm();
        }
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: categoryValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  useEffect(() => {
    _get_category_list();
  }, []);

  const handleDDChange = (e, val) => {
    formik.setFieldValue("parentcategory", val);
    formik.setFieldValue("parentcategoryid", val === null ? 0 : val?.id);
  };

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_category(data, {
          onSuccess: () => {
            _get_category_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
          }
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "Category",
        accessor: "category"
      },
      {
        Header: "Parent Category",
        accessor: "parentcategory"
      },
      {
        Header: "Featured?",
        accessor: "isfeatured",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isfeatured ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "Web Display",
        accessor: "displayonweb",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.displayonweb ? (
            <Circle fontSize="small" color={"success"} />
          ) : (
            <Circle fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "View Image",
        accessor: "categoryimage",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.categoryimage !== null ? (
            <Link
              aria-disabled={true}
              target="_blank"
              to={`${keys.ServicePath}/${cellProps.row.original.categoryimage}`}
              className="align-center"
              title={cellProps.row.original.categoryimage}
              aria-label={cellProps.row.original.categoryimage}
            >
              <ImageIcon fontSize="medium" />
            </Link>
          ) : (
            <Image fontSize="small" htmlColor="#dddddd  " />
          );
        }
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            const { parentcategoryid } = cellProps.row.original;
            setFormData({
              ...cellProps.row.original,
              file: {
                name:
                  cellProps.row.original.categoryimage !== null
                    ? cellProps.row.original.categoryimage.split("/").pop()
                    : ""
              },
              isfeatured: Boolean(cellProps.row.original.isfeatured),
              displayonweb: Boolean(cellProps.row.original.displayonweb),
              parentcategory:
                parentcategoryid > 0
                  ? category_list.find((x) => x.id === parentcategoryid)
                  : null
            });
            // formik.setValues({
            //   ...cellProps.row.original,
            //   isfeatured: Boolean(cellProps.row.original.isfeatured),
            //   displayonweb: Boolean(cellProps.row.original.displayonweb)
            // });
            // navigate(`/addgrn`, { state: { id: cellProps.row.original.id } });
          },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  const onFileChange = (result) => {
    formik.setFieldValue("file", result);
    formik.setFieldValue("documentsize", result?.size);
    formik.setFieldValue("documenttype", result?.type);
    setFormData((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type
    }));
  };

  useEffect(() => {
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  return {
    formik,
    formData,
    initialValues,
    category_list,
    column,
    actions: { handleSubmit, onGridDelete, handleDDChange, onFileChange },
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};
