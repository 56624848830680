import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  createFilterOptions
} from "@mui/material";

import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  one_column_grid,
  textFieldProps,
  two_column_grid
} from "../../common/controlProps";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import { AddShoppingCart, Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";
import FileToBase64 from "../../components/FileBaseTo64";
import DeleteModal from "../../components/DeleteModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { keys } from "../../config/keys";
import { useSalesHooks } from "./useSalesHooks";
import { MenuItem } from "@mui/base";

const SalesMaster = () => {
  const {
    formik,
    product_list,
    customer_list,
    productDetails,
    salesTransaction,
    productDD,
    actions,
    column,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useSalesHooks();

  const filter = createFilterOptions();

  return (
    <MainCard title="Sales Order">
      <Grid container spacing={1}>
        <Grid item xl={12} xs={12} sm={12} md={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Divider textAlign="left" className="mt-2">
              <Chip
                label="Customer/ Order Information"
                size="small"
                color="primary"
              />
            </Divider>
            <Grid container spacing={2}>
              <Grid item {...two_column_grid}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Order Date"
                    format={keys.shortDateFormat}
                    slotProps={{
                      textField: {
                        ...textFieldProps
                      }
                    }}
                    onChange={actions.onDateChange}
                    value={formik?.values?.orderdate}
                    clearable
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Invoice #"
                  name="invoiceno"
                  onChange={formik.handleChange}
                  value={formik.values.invoiceno}
                  error={formik.errors.invoiceno}
                  helperText={`Last Invoice #: ${formik.values.lastinvoiceno}`}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="customer"
                  size="small"
                  freeSolo
                  options={customer_list}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return `${option.label}`;
                    }
                    return option.label;
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.label
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        id: 0,
                        inputValue: `Add "${inputValue}"`,
                        label: inputValue
                      });
                    }

                    return filtered;
                  }}
                  onChange={actions.handleCustomerChange}
                  value={formik.values.customer}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Mobile #"
                      error={formik.errors.customer}
                    />
                  )}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Customer Name"
                  name="customername"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.customername}
                  error={formik.errors.customername}
                  // disabled={formik.values.customerid > 0}
                  helperText={
                    formik.touched.customername && formik.errors?.customername
                  }
                />
              </Grid>

              <Grid item md={6} sm={12} lg={6}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Address"
                  name="address"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Pincode"
                  name="pincode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.pincode}
                />
              </Grid>
            </Grid>

            <Divider textAlign="left" className="mt-2">
              <Chip label="Product Information" size="small" color="primary" />
            </Divider>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="product"
                  size="small"
                  options={product_list}
                  getOptionDisabled={(option) => option.stock <= 0}
                  getOptionLabel={(option) => option.label}
                  onChange={actions.handleProductChange}
                  value={productDD}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      label="Product"
                      //error={formik.errors.product}
                    />
                  )}
                />
                <FormHelperText className="text-primary fw-bold ">{`Current Stock: ${
                  productDD?.stock || "-"
                }`}</FormHelperText>
              </Grid>

              <Grid item {...one_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Quantity"
                  name="qty"
                  onChange={actions.handleTextChange}
                  value={productDetails?.qty}
                  error={productDetails?.qty > productDD?.stock}
                  helperText={
                    productDetails?.qty > productDD?.stock &&
                    "Qty Should not be greater than stock"
                  }
                />
              </Grid>

              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Price"
                  name="price"
                  onChange={actions.handleTextChange}
                  value={productDetails.price}
                />
              </Grid>

              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Discount"
                  name="discount"
                  onChange={actions.handleTextChange}
                  value={productDetails.discount}
                />
              </Grid>

              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Taxable Amount"
                  name="taxableamount"
                  disabled
                  // onChange={actions.handleTextChange}
                  value={productDetails.taxableamount}
                />
              </Grid>

              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Tax"
                  name="tax"
                  disabled
                  // onChange={actions.handleTextChange}
                  value={productDetails.tax}
                />
              </Grid>

              <Grid item {...two_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="number"
                  label="Net Total"
                  name="nettotal"
                  disabled
                  //onChange={actions.handleTextChange}
                  value={productDetails.nettotal}
                />
              </Grid>

              <Grid item {...two_column_grid}>
                {/* <IconButton
                  color="primary"
                  aria-label="add to shopping cart"
                  size="medium"
                  className="custom-btn-page-layout"
                  onClick={actions.onAddClick}
                  disabled={
                    productDetails.productid <= 0 ||
                    productDetails.nettotal <= 0
                  }
                >
                  <AddShoppingCart fontSize="medium" />
                </IconButton> */}
                <Button
                  color="primary"
                  aria-label="add to shopping cart"
                  size="medium"
                  className="custom-btn-page-layout"
                  onClick={actions.onAddClick}
                  disabled={
                    productDetails.productid <= 0 ||
                    productDetails.nettotal <= 0
                  }
                  startIcon={<AddShoppingCart fontSize="medium" />}
                >
                  {`Add Item`}
                </Button>
              </Grid>
            </Grid>
            {salesTransaction.length > 0 && (
              <Grid item xl={12} md={12} sm={12} className="border-1">
                <div className="border border-secondary-subtle mt-2">
                  <TableContainer
                    columns={column}
                    data={salesTransaction}
                    isGlobalFilter={false}
                    showColumnFilters={false}
                    exports={{ excel: false, pdf: false }}
                    isAddUserList={false}
                    showFooter={true}
                    tableClass={"table-sm"}
                    divClass={"table-responsive"}
                    showPagination={salesTransaction.length > 10 ? true : false}
                    initialState={{ pageSize: 10, pageIndex: 0 }}
                  />
                </div>
              </Grid>
            )}
            <Divider textAlign="left" className="mt-2">
              <Chip
                label="Shipping & Payment Information"
                size="small"
                color="primary"
              />
            </Divider>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Shipped By"
                  name="shippedby"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.shippedby}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Shipping Reference #"
                  name="shippingrefrenceno"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.shippingrefrenceno}
                />
              </Grid>
              <Grid item {...four_column_grid}>
                <Autocomplete
                  name="paymentmethod"
                  size="small"
                  options={["Cash", "Online", "Card"]}
                  // getOptionLabel={(option) => option.label}
                  onChange={(e, val) =>
                    formik.setFieldValue("paymentmethod", val)
                  }
                  value={formik.values.paymentmethod}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      label="Payment Mode"
                      //error={formik.errors.product}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="submit"
                  color="success"
                  startIcon={<Save />}
                >
                  Save
                </StyledButton>
              </Grid>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="button"
                  color="error"
                  startIcon={<Cancel />}
                  onClick={actions.resetForm}
                >
                  {`Cancel`}
                </StyledButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>

      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
    </MainCard>
  );
};

export default SalesMaster;
