import { createSlice } from "@reduxjs/toolkit";
import { VENDOR_LIST } from "../../constTypes";

const initialState = {
  status: "idle",
  fields: [
    {
      field: "company",
      headerName: "Company Name",
      width: 100,
      align: 'left',
      editable: true,
    },
    {
      field: "contact_person",
      headerName: "Contact Person",
      width: 100,
      align: 'left',
      editable: true,
    },
    {
      field: "contact_phone",
      headerName: "Contact Phone",
      width: 100,
      align: 'left',
      editable: true,
    },
    {
      field: "contact_email",
      headerName: "Email",
      width: 100,
      align: 'left',
      editable: true,
    },
    {
      field: "TAN",
      headerName: "TAN",
      width: 100,
    },
    {
      field: "gstno",
      headerName: "GSTNO",
      width: 100,
    },
    {
      field: "address",
      headerName: "Address",
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 40,
    },
  ],
  vendorUsers: [],
};

const vendorSlice = createSlice({
  name: "vendorSlice",
  initialState,
  reducers: {
  }
})



export const vendor_list = (state = [], action) => {
  switch (action.type) {
    case VENDOR_LIST:
      return action.payload || [];
    default:
      return state;
  }
};


export default vendorSlice.reducer;