import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  OutlinedInput,
  TextField
} from "@mui/material";
import React from "react";
import { useAsyncDebounce } from "react-table";

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    Header,
    filterValue,
    setFilter,
    preFilteredRows: { length }
  }
}) => {
  return (
    <TextField
      id="filter-text"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${Header} ...`}
      size="small"
      hiddenLabel
      variant="standard"
      fullWidth
    />
    // <div className="input-group input-group-sm">
    //   <input
    //     type="text"
    //     id="filter-text"
    //     className="input-group input-group-sm form-control"
    //     value={filterValue || ""}
    //     onChange={(e) => {
    //       setFilter(e.target.value || undefined);
    //     }}
    //     placeholder={`Search ${Header} ...`}
    //   />
    // </div>
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, Header }
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option}
      id="filter-auto-complete"
      autoComplete
      onChange={(e, opt) => setFilter(opt || undefined)}
      includeInputInList
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          hiddenLabel
          placeholder={`Search ${Header} ...`}
        />
      )}
    />
  );
};

// Define a default UI for filtering
export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    
      <div className="input-group">
        <button
          className="btn btn-outline-secondary btn-sm d-flex align-items-center"
          type="button"
          disabled
          id="search-bar"
        >
          <i className="fa fa-search" />
        </button>
        <input
          type="text"
          placeholder={`Search this table from  ${count} records...`}
          className="form-control form-control-sm"
          id="search-input-bar"
          aria-describedby="search-bar"
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          value={value || ""}
        />
      </div>
  );
};

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        ref={resolvedRef}
        {...rest}
        size="small"
        color="warning"
        className="grid_checkbox"
      />
    );
  }
);
