import axios from "../../common/axios";
import { keys } from "../../config/keys";
import { _dispatchPayload } from "./dispatchPayload";
import { BLOG_LIST } from "../constTypes";

export const get_blog_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/blogs`)
    .then((res) => {
      dispatch(_dispatchPayload(BLOG_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const save_blog = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/blogs`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_blog = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/blogs/delete`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const deactive_blog = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/blogs/deactive`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};
