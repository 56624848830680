// project import
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "./store/actions/auth";
import axios, { setAuthorizationToken } from "./common/axios";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

let AuthContext = React.createContext(null);
export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export const AuthProvider = ({ children, userData }) => {
  let [user, setUser] = useLocalStorage("user", userData);
  // React.useState<any>(null);
  const [data, setData] = React.useState(null);
  const dispatch = useDispatch();

  let signin = (newUser, callback) => {
    dispatch(
      login(newUser, {
        onSuccess: (res) => {
          
          setAuthorizationToken(res.data.token);
          axios.defaults.headers.common["token"] = res.data.token;
          Axios.defaults.headers.common["token"] = res.data.token;
          setUser({ ...res.data.user, token: res.data.token });
          setData(res.data);

          callback(res);
        },
        onError: (res) => {
          
          toast.warning(res.message);
        }
      })
    );
    // return fakeAuthProvider.signin(() => {

    //   setUser(newUser);
    //   callback();
    // });
  };

  let signout = (callback) => {
    localStorage.clear();
    sessionStorage.clear();
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = useMemo(
    () => ({
      user,
      signin,
      signout,
      data
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  }
};

const App = () => (
  <AuthProvider>
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
    <ToastContainer
      position="top-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      theme="colored"
    />
  </AuthProvider>
);
export default App;
