import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";
import { Button, Grid } from "@mui/material";
import { Table, Row, Col } from "reactstrap";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import * as XLSX from "xlsx/xlsx.mjs";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  DefaultColumnFilter,
  GlobalFilter,
  IndeterminateCheckbox
} from "./filters.js";
import {
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Print
} from "@mui/icons-material";
import LoaderComponent from "../../components/Loader.js";
import { ExcelIcon, PdfIcon } from "../../Icons";
import dayjs from "dayjs";
import { keys } from "../../config/keys.js";

const TableContainer = ({
  columns,
  data,
  isGlobalSearch,
  isGlobalFilter = true,
  isAddOptions,
  isAddUserList = false,
  isAddCustList,
  tableClass,
  theadClass,
  thClass,
  divClass,
  apiCallFunction,
  rowDetails,
  totalCount,
  limit,
  offset,
  setOffset,
  initialState = { pageSize: 10, pageIndex: 0, selectedRowIds: {} },
  filterColumn,
  showPagination = false,
  showColumnFilters = false,
  showFooter = false,
  showRowSelection = false,
  rowStyle = () => {},
  rowClass = () => {},
  loading = false,
  exports = {
    excel: true,
    pdf: true,
    print: false
  },
  exportfileName = `data_${dayjs(Date()).format(keys.dbDateFormat)}`,
  onRowSelectionChange
}) => {
  const getExportFileBlob = ({
    columns,
    data,
    fileType,
    fileName = exportfileName
  }) => {
    if (fileType === "csv") {
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header
      });
      XLSX.utils.book_append_sheet(wb, ws1, exportfileName);
      XLSX.writeFile(wb, `${exportfileName}.xlsx`);
      return false;
    } else if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 10 },
        theme: "grid",
        styles: {
          halign: "left",
          valign: "middle",
          fontSize: 8
        },
        headStyles: { fillColor: [57, 139, 35] }
      });
      doc.save(`${exportfileName}.pdf`);
      return false;
    } else if (fileType === "print") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 10 },
        theme: "grid",
        styles: {
          halign: "left",
          valign: "middle",
          fontSize: 8
        },
        headStyles: { fillColor: [57, 139, 35] }
      });
      doc.autoPrint();
      window.open(doc.output("bloburl"));
      return false;
    }

    return false;
  };

  const getRowId = (row, relativeIndex, parent) => {
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    // console.log(parent, row);
    return row?.id;
  };

  const onRowChange = () => {};

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    pageOptions,
    setPageSize,
    setFilter,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    pageCount,
    canNextPage,
    canPreviousPage,
    gotoPage,
    previousPage,
    nextPage,
    visibleColumns,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
    exportData
  } = useTable(
    {
      columns,
      data,
      getRowId,
      initialState: initialState,
      onRowSelectionChange,
      defaultColumn: {
        Filter: DefaultColumnFilter
      },
      getExportFileBlob
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect,
    (hooks) => {
      showRowSelection &&
        hooks.allColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            )
          },
          ...columns
        ]);
    }
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <ArrowDropDown />
      ) : (
        <ArrowDropUp />
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  useEffect(() => {
    if (onRowSelectionChange) {
      onRowSelectionChange(selectedRowIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds, selectedFlatRows]);

  // useMountedLayoutEffect(() => {
  //   onRowSelectionChange && onRowSelectionChange(selectedRowIds);
  // }, [onRowSelectionChange, selectedRowIds]);

  return (
    <Fragment>
      {isGlobalSearch ||
        isGlobalFilter ||
        isAddOptions ||
        isAddUserList ||
        (isAddCustList && (
          <Row className="mb-2">
            {isGlobalSearch && (
              <Col md={1}>
                <select
                  className="form-select form-select-sm"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            )}
          </Row>
        ))}
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds,
              selectedFlatRows: selectedFlatRows.map((d) => d.original),
            },
            null,
            2
          )}
        </code>
      </pre> */}
      <div className={divClass}>
        <Table
          hover
          {...getTableProps()}
          className={`${tableClass} table-sm align-middle tbl-grid`}
        >
          <thead className={theadClass}>
            {(exports.excel || exports.pdf || exports.print) && (
              <tr>
                <th colSpan={visibleColumns.length}>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-end"
                      style={{ gap: 8 }}
                    >
                      {exports.excel && (
                        <Button
                          variant="outlined"
                          startIcon={<ExcelIcon />}
                          size="small"
                          color="success"
                          onClick={() => {
                            exportData("xlsx", true);
                          }}
                        >
                          Export Excel
                        </Button>
                      )}
                      {exports.pdf && (
                        <Button
                          variant="outlined"
                          startIcon={<PdfIcon />}
                          size="small"
                          color="error"
                          onClick={() => {
                            exportData("pdf", true);
                          }}
                        >
                          Export PDF
                        </Button>
                      )}
                      {exports.print && (
                        <Button
                          variant="outlined"
                          startIcon={<Print />}
                          size="small"
                          color="secondary"
                          onClick={() => {
                            exportData("print", true);
                          }}
                        >
                          Print
                        </Button>
                      )}
                    </Col>
                  </Row>
                </th>
              </tr>
            )}
            {isGlobalFilter && (
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: "left"
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr>
            )}
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      key={column.id}
                      className={thClass}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </th>
                  );
                })}
              </tr>
            ))}
            {showColumnFilters &&
              headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        key={column.id}
                        className={thClass}
                        {...column.getHeaderProps()}
                      >
                        {column.canFilter ? column.render("Filter") : null}
                      </th>
                    );
                  })}
                </tr>
              ))}

            {filterColumn && filterColumn()}
          </thead>

          {loading ? (
            <LoaderComponent />
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    key={row.getRowProps().key}
                    style={{ ...rowStyle(row) }}
                    className={rowClass(row)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          {...cell.getCellProps()}
                          style={{ ...cell.column.style }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}

          {showFooter && (
            <tfoot className={theadClass}>
              {footerGroups.map((footerGroup) => (
                <tr {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column) => (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </Table>
      </div>
      {showPagination && (
        <div className="pagination">
          <Button
            color="primary"
            size="small"
            variant="contained"
            className="pagination-btn btn-sm"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            startIcon={<KeyboardDoubleArrowLeft />}
          />
          <Button
            color="success"
            size="small"
            variant="contained"
            className="pagination-btn btn-sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            startIcon={<KeyboardArrowLeft />}
          />
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <Button
            color="success"
            size="small"
            variant="contained"
            className="pagination-btn btn-sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            startIcon={<KeyboardArrowRight />}
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            className="pagination-btn btn-sm"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            startIcon={<KeyboardDoubleArrowRight />}
          />
          <span className="d-flex align-items-center">
            {` | Go to page: `}
            <input
              type="number"
              className="form-control form-control-sm ms-1"
              defaultValue={pageIndex + 1}
              min={1}
              max={pageCount - 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "75px" }}
            />
          </span>{" "}
          <select
            className="select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default TableContainer;
