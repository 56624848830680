import {  PURCHASE_LIST } from "../constTypes";

export const purchase_list = (state = [], action) => {
  switch (action.type) {
    case PURCHASE_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
