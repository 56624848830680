import {
  LoginOutlined,
  ProfileOutlined,
  UserOutlined,
  TeamOutlined
} from "@ant-design/icons";
import {
  Category,
  DryCleaning,
  Inventory,
  ShoppingBag,
  Book
} from "@mui/icons-material";
import { useAuth } from "../App";

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  UserOutlined,
  TeamOutlined,
  Category,
  DryCleaning,
  Inventory,
  ShoppingBag,
Book
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //
const adminpages = {
  id: "masters",
  title: "Masters",
  type: "group",
  children: [
    {
      id: "user",
      title: "Users",
      type: "item",
      url: "/app/usermaster",
      icon: icons.UserOutlined
    },
    {
      id: "vendor",
      title: "Vendor",
      type: "item",
      url: "/app/vendormaster",
      icon: icons.TeamOutlined
    },
    {
      id: "category",
      title: "Category",
      type: "item",
      url: "/app/categorymaster",
      icon: icons.Category
    },
    {
      id: "product",
      title: "Product",
      type: "item",
      url: "/app/productlist",
      icon: icons.DryCleaning
    },
    {
      id: "purchase",
      title: "Purchase",
      type: "item",
      url: "/app/purchase",
      icon: icons.Inventory
    },
    {
      id: "sales",
      title: "Sales",
      type: "item",
      url: "/app/sales",
      icon: icons.ShoppingBag
    },
    {
      id: "salesonline",
      title: "Online Sales List",
      type: "item",
      url: "/app/salesonline",
      icon: icons.ShoppingBag
    },
    {
      id: "blogs",
      title: "Blogs",
      type: "item",
      url: "/app/blogs",
      icon: icons.Book
    }
  ]
};

const operationpages = {
  id: "Operations",
  title: "Operations",
  type: "group",
  children: [
    {
      id: "purchase",
      title: "Purchase",
      type: "item",
      url: "/app/purchase",
      icon: icons.Inventory
    },
    {
      id: "sales",
      title: "Sales",
      type: "item",
      url: "/app/sales",
      icon: icons.ShoppingBag
    }
  ]
};
const salespages = {
  id: "Operations",
  title: "Operations",
  type: "group",
  children: [
    {
      id: "sales",
      title: "Sales",
      type: "item",
      url: "/app/sales",
      icon: icons.ShoppingBag
    }
  ]
};
const nopages = {
  id: "No Role",
  title: "",
  type: "group",
  children: []
};
const usePages = () => {

  const auth = useAuth();

  if ([1, 2, 3].includes(auth?.user?.usertypeid)) return adminpages;
  else if (auth?.user?.usertypeid === 4) return operationpages;
  else if (auth?.user?.usertypeid === 5) return salespages;
  else return nopages;
};

export default usePages;
