export const textFieldProps = {
    variant: "standard",
    size: "small",
    fullWidth: true,
    autoComplete: false,
  };
  
export const four_column_grid = {
    xl:3,
    md:3,
    sm:12,
    xs:12
} 
export const one_column_grid = {
  xl:1,
  lg:1,
  md:1,
  sm:12,
  xs:12
} 
export const two_column_grid = {
  xl:2,
  lg:2,
  md:2,
  sm:12,
  xs:12
} 




export const button_grid = {
    xl:1,
    md:1,
    sm:12,
    xs:12
}

export const buttonFieldProps = {
    variant: "contained",
    size: "small",
    fullWidth: true,
  };