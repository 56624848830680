import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../common/axios";
import { keys } from "../../config/keys";
import { _dispatchPayload } from "./dispatchPayload";
import { USER_LIST, USER_TYPES } from "../constTypes";

// export const getAdminUsers = createAsyncThunk("getAdminUsers", async () => {
//   const adminUsers = await fetch(`${keys.ServicePath}/api/users`).then((res) =>
//     res.json()
//   );

//   return adminUsers;
// });

// export const postAdminUser = createAsyncThunk(
//   "postAdminUser",
//   async (userData) => {
//     const postAdminUserResponse = await fetch(
//       `${keys.ServicePath}/admin/users/add`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json"
//         },
//         body: JSON.stringify(userData)
//       }
//     ).then((res) => res.json());

//     return postAdminUserResponse;
//   }
// );

export const get_user_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/users`)
    .then((res) => {
      dispatch(_dispatchPayload(USER_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_user_type_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/common/user_type`)
    .then((res) => {

      dispatch(_dispatchPayload(USER_TYPES, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};


export const save_user = (data, callback) => async (dispatch) => {

  await axios
    .post(`${keys.ServicePath}/api/users`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const delete_user = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/users/delete`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};