import { BLOG_LIST } from "../constTypes";

export const blogs_list = (state = [], action) => {
  switch (action.type) {
    case BLOG_LIST:
      return [...action.payload] || [];
    default:
      return state;
  }
};
