/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { salesValidationSchema } from "./validationSchema";
import { useFormik } from "formik";
import { GridAction } from "../../common/useGridActions";
import { keys } from "../../config/keys";
import dayjs from "dayjs";
import { delete_purchase, save_purchase } from "../../store/actions/purchase";
import {
  customerAction,
  salesAction,
  productAction
} from "../../store/actions";
import {
  delete_sales,
  get_sales_by_id,
  save_sales
} from "../../store/actions/sales";
import { IconButton } from "@mui/material";
import { Print } from "@mui/icons-material";
import Invoice from "./Invoice";
import { useReactToPrint } from "react-to-print";

const initialValues = {
  id: 0,
  invoiceno: "",
  lastinvoiceno: "",
  orderdate: dayjs(Date()),
  orderdatestring: dayjs(Date()).format(keys.dbDateFormat),
  customerid: 0,
  customer: "",
  mobile: "",
  customername: "",
  address: "",
  pincode: "",
  discounts: 0,
  producttotal: 0,
  taxes: 0,
  nettotal: 0,
  shippedby: "",
  shippingrefrenceno: "",
  paymentmethod: "",
  source: "Store"
};

const initial_product_details = {
  id: 0,
  salesid: 0,
  productid: 0,
  product: null,
  qty: 1,
  price: 0,
  discount: 0,
  taxableamount: 0,
  tax: 0,
  nettotal: 0,
  sgst: 0,
  cgst: 0,
  stocktype: "S"
};

export const useSalesHooks = () => {
  const dispatch = useDispatch();

  const { product_list, customer_list } = useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const [productDetails, setProductDetails] = useState(initial_product_details);
  const [productDD, setselectedProductDD] = useState(null);
  const [salesTransaction, setSalesTransaction] = useState([]);
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_product_list = () => dispatch(productAction.store_product_dd());
  const _get_customer_list = () => dispatch(customerAction.get_customer_list());
  const _get_last_invoice_no = () =>
    dispatch(
      salesAction.get_last_invoiceno("sales_master", {
        onSuccess: (res) => {
          if (res.length > 0) {
            formik.setFieldValue("invoiceno", res[0].invoiceno);
            formik.setFieldValue("lastinvoiceno", res[0].lastinvoiceno);
          }
        }
      })
    );

  const handleSubmit = (values) => {
    const { orderdate, ...others } = values;

    const producttotal = salesTransaction.reduce(
      (sum, row) => Number(row.taxableamount) + sum,
      0
    );
    const discounts = salesTransaction.reduce(
      (sum, row) => Number(row.discount) + sum,
      0
    );
    const taxes = salesTransaction.reduce((sum, row) => row.tax + sum, 0);
    const nettotal = salesTransaction.reduce(
      (sum, row) => Number(row.nettotal) + sum,
      0
    );
    const data = {
      ...others,
      producttotal,
      discounts,
      taxes,
      nettotal,
      salestransaction: salesTransaction
    };
    dispatch(
      save_sales(data, {
        onSuccess: () => {
          setFormData({ ...initialValues });
          formik.resetForm();
          setSalesTransaction([]);
          _get_last_invoice_no();
        }
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: salesValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  useEffect(() => {
    _get_last_invoice_no();
    _get_product_list();
    _get_customer_list();
  }, []);

  const handleProductChange = (e, val) => {
    setselectedProductDD(val);

    if (val !== null) {
      const { id, label, offline_sales_price = 0, sgst = 0, cgst = 0 } = val;
      setProductDetails((prev) => ({
        ...prev,
        productid: id,
        productname: label,
        price: offline_sales_price,
        sgst,
        cgst
      }));
      calculateSales(val);
    }
  };

  const calculateSales = (productVal) => {
    const { price, sgst, cgst, qty, discount } = productDetails;
    const taxableamount = (price - discount) * qty;
    const tax = (taxableamount * (sgst + cgst)) / 100;
    const nettotal = Math.round(taxableamount + tax).toFixed(2);

    setProductDetails((prev) => ({
      ...prev,
      taxableamount,
      tax,
      nettotal
    }));
  };

  const handleTextChange = (e) => {
    setProductDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (productDD !== null) calculateSales(productDD);
  }, [productDetails.qty, productDetails.discount, productDetails.price]);

  const handleCustomerChange = (e, val) => {
    console.log(val);
    if (typeof val === "string") {
      formik.setFieldValue("customer", val);
      formik.setFieldValue("mobile", val);
      formik.setFieldValue("customername", "");
      formik.setFieldValue("address", "");
      formik.setFieldValue("pincode", "");
      formik.setFieldValue("customerid", 0);
    } else {
      formik.setFieldValue("customer", val);
      formik.setFieldValue("customername", val?.customername);
      formik.setFieldValue("address", val?.address);
      formik.setFieldValue("pincode", val?.pincode);
      formik.setFieldValue("mobile", val?.label);
      formik.setFieldValue("customerid", val?.id);
    }
  };

  const onGridDelete = () => {
    const { data } = deleteContainer;

    const t = salesTransaction.filter((x) => x.id !== data.id);
    setSalesTransaction([...t]);

    setDeleteContainer({
      show: false,
      isDeleting: false,
      data
    });
  };

  const column = useMemo(
    () => [
      {
        Header: "Product",
        accessor: "productname",
        Footer: "TOTAL"
      },
      {
        Header: "Qty",
        accessor: "qty",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => Number(row.values.qty) + sum, 0),
            [info.rows]
          );

          return total;
        }
      },
      {
        Header: "Price",
        accessor: "price"
      },
      {
        Header: "Discount",
        accessor: "discount",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.discount) + sum, 0),
            [info.rows]
          );
          // formik.setFieldValue("discounts",total);
          return total;
        }
      },
      {
        Header: "Taxable Amount",
        accessor: "taxableamount",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () =>
              info.rows.reduce((sum, row) => Number(row.values.taxableamount) + sum, 0),
            [info.rows]
          );
          // formik.setFieldValue("taxableamount",total);
          return total;
        }
      },
      {
        Header: "Tax",
        accessor: "tax",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.tax) + sum, 0),
            [info.rows]
          );
          // formik.setFieldValue("tax",total);
          return Number(total).toFixed(2);
        }
      },
      {
        Header: "Net Total",
        accessor: "nettotal",
        Footer: (info) => {
          // Only calculate total visits if rows change
          const total = useMemo(
            () => info.rows.reduce((sum, row) => Number(row.values.nettotal) + sum, 0),
            [info.rows]
          );
          // formik.setFieldValue("nettotal",total);
          return Number(total).toFixed(2);
        }
      },

      {
        ...GridAction({
          permission: { edit: false, delete: true },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  const onAddClick = () => {
    const temp = [...salesTransaction];
    temp.push({
      ...productDetails,
      id: Math.random(),
      product: productDD,
      productname: productDD.label
    });

    setSalesTransaction([...temp]);

    setselectedProductDD(null);
    setProductDetails(initial_product_details);
  };

  // useEffect(() => {
  //   setFormData((prev) => ({ ...formik.values }));
  // }, [formik.values]);

  const onDateChange = (dateValue) => {
    // setFormData((prev) => ({
    //   ...prev,
    //   purchasedate: dateValue,
    //   purchasedatestring: dayjs(dateValue).format("YYYY-MM-DD")
    // }));
    formik.setValues({
      ...formik.values,
      orderdate: dateValue,
      orderdatestring: dayjs(dateValue).format("YYYY-MM-DD")
    });
  };

  const resetForm = () => setFormData({ ...initialValues });

  return {
    formik,
    formData,
    initialValues,
    product_list,
    customer_list,
    productDetails,
    salesTransaction,
    column,
    productDD,
    actions: {
      handleSubmit,
      onGridDelete,
      resetForm,
      onDateChange,
      handleProductChange,
      handleCustomerChange,
      handleTextChange,
      onAddClick
    },
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};

export const useSalesListHooks = () => {
  const dispatch = useDispatch();
  const { sales_list } = useSelector((obj) => obj);
  const [salesId, setSalesId] = useState(0);
  const [data, setData] = useState();

  const printComponentRef = useRef();

  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_sales_list = () => dispatch(salesAction.get_sales_list());

  const _get_sales_by_id = (id) =>
    dispatch(
      get_sales_by_id(id, {
        onSuccess: async (res) => {
          if (res[0].length > 0) {
            //console.log(res[0], res[1]);
            await setData({
              ...res[0][0],
              productdetails: res[1]
            });
            onPrintInvoice(null, () => printComponentRef.current);
          }
        }
      })
    );

  useEffect(() => {
    _get_sales_list();
  }, []);

  const column = useMemo(
    () => [
      {
        Header: "Order Date",
        accessor: "orderdate",
        Cell: ({ value }) => keys.shortDate(value)
      },
      {
        Header: "Invoice #",
        accessor: "invoiceno"
      },
      {
        Header: "Customer",
        accessor: "customername"
      },
      {
        Header: "Mobile",
        accessor: "mobile"
      },
      {
        Header: "Net Total",
        accessor: "nettotal",
        Cell: ({ value }) => keys.decimalNumber(value)
      },
      {
        id: "print",
        Header: "",
        Cell: (cellProps) => (
          <IconButton
            size={"small"}
            color="primary"
            onClick={async () => {
              setSalesId(cellProps.row.original.id);
              // _get_sales_by_id(cellProps.row.original.id);
              // onPrintInvoice();
              // <Invoice id={cellProps.row.original.id} />;
            }}
          >
            <Print fontSize={"small"} />
          </IconButton>
        )
      },
      {
        ...GridAction({
          permission: { edit: false, delete: true },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_sales(data, {
          onSuccess: () => {
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
            _get_sales_list();
          }
        })
      );
    }
  };

  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const onPrintInvoice = useReactToPrint({
    content: () => printComponentRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        console.log(resolve);
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    }
  });
  // const onPrintInvoice = useReactToPrint({
  //   documentTitle: "Print This Document",
  //   onBeforePrint: () => ,
  //   onAfterPrint: () => console.log("after printing..."),
  //   removeAfterPrint: true
  //   // content: () => printComponentRef.current,
  //   // onBeforeGetContent: () => {
  //   //   return new Promise((resolve) => {
  //   //     printComponentRef.current = resolve;
  //   //     //setIsPrinting(true);
  //   //   });
  //   // },
  // });

  return {
    sales_list,
    salesId,
    column,
    data,
    printComponentRef,
    actions: { onGridDelete },
    delete: {
      deleteContainer,
      setDeleteContainer
    }
  };
};

const useInvoiceHooks = (id) => {
  const dispatch = useDispatch();
  const { sales_list } = useSelector((obj) => obj);

  return sales_list;
};
