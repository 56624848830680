import { FormHelperText, Grid, TextField } from "@mui/material";
import { Form } from "reactstrap";
import { CKEditor, Base64UploadAdapter } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
// import { CKEditor } from "ckeditor4-react";
import MainCard from "../../components/MainCard";
import { useBlogHooks } from "./useBloghooks";
import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  textFieldProps
} from "../../common/controlProps";
import StyledButton from "../../components/Button";
import { Save, Cancel } from "@mui/icons-material";
import FileToBase64 from "../../components/FileBaseTo64";
import { useState } from "react";

const Blogs = () => {
  const { formik, actions, formData } = useBlogHooks();

  const [editorData, setEditorData] = useState("");
  const editorConfiguration = {
    // plugins: [Base64UploadAdapter],
    simpleUpload: {
      // The URL that the images are uploaded to.
      uploadUrl: "http://localhost:5056/api/upload",

      // Enable the XMLHttpRequest.withCredentials property.
      withCredentials: true,

      // Headers sent along with the XMLHttpRequest to the upload server.
      headers: {
        "X-CSRF-TOKEN": "CSRF-Token",
      }
    },
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      uploadUrl:
        "https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json"
    }
  };
  return (
    <MainCard title="Create Blog">
      <Grid container spacing={1}>
        <Grid item xl={12} xs={12} sm={12} md={12}>
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item {...four_column_grid}>
                <TextField
                  {...textFieldProps}
                  type="text"
                  label="Title"
                  name="title"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  error={formik.errors.title}
                  helperText={
                    formik.touched.title && formik.errors?.title
                  }
                  
                />
              </Grid>
              <Grid item {...four_column_grid}>
                {formData.file && formData.file?.name && (
                  <FormHelperText>{formData.file?.name} </FormHelperText>
                )}
                <FileToBase64
                  onChange={actions.onFileChange}
                  buttontitle="Upload Description Image"
                />
                {formData.file && formData.file?.name && (
                  <img src={formData.file.base64} alt="p_image" width={75} />
                )}
              </Grid>
              {/* <Grid item>
                <CKEditor
                  editor={Editor}
                  className="ck_description"
                  id={"description"}
                  data={formData.description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //console.log("Editor is ready to use!", editor.getData());
                  }}
                  onChange={(event, editor) => {
                    console.log("event editor", editor.data);
                    if (editor.data !== undefined)
                      actions.handleEditorChange(editor, "description");
                  }}
                  onBlur={(event, editor) => {
                    //console.log("Blur.", editor.sourceElement);
                  }}
                  onFocus={(event, editor) => {
                    // console.log("Focus.", editor.data);
                  }}
                />
              </Grid> */}
              <Grid item>
                {/* <CKEditor
                  initData={formData.content}
                  data={formData.content}
                  onInstanceReady={({ editor }) => {
                    console.log("Editor is ready!");
                    editor.setData(formData.content);
                  }}
                /> */}
                <CKEditor
                  editor={Editor}
                  data={formData.content}
                  config={editorConfiguration}
                  id={"content"}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //console.log("Editor is ready to use!", editor.getData());
                  }}
                  onChange={(event, editor) => {
                    // console.log("event editor", editor.getData());
                    // setEditorData(editor.getData());
                    // const t = editor.getData();
                    // if (editor.data !== undefined)
                    //   actions.handleEditorChange(t, "content");
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor.getData());
                    actions.handleEditorChange(editor.getData(), "content");
                  }}
                  onFocus={(event, editor) => {
                    // console.log("Focus.", editor.data);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={1}>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="submit"
                  color="success"
                  startIcon={<Save />}
                >
                  Save
                </StyledButton>
              </Grid>
              <Grid item {...button_grid}>
                <StyledButton
                  {...buttonFieldProps}
                  type="button"
                  color="error"
                  startIcon={<Cancel />}
                >
                  {`Cancel`}
                </StyledButton>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Blogs;
