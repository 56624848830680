import { keys } from '../../config/keys';
import axios from '../../common/axios';
import { _dispatchPayload } from './dispatchPayload';
import { VENDOR_LIST } from '../constTypes';

export const get_vendor_list = (callback) => async (dispatch) => {
    await axios
        .get(`${keys.ServicePath}/api/vendor`)
        .then((res) => {

            dispatch(_dispatchPayload(VENDOR_LIST, res.data.rows));
            if (callback?.onSuccess) callback.onSuccess(res.data);
        })
        .catch((e) => {
            if (callback?.onError) callback?.onError(e);
        });
};

export const save_vendor = (data, callback) => async (dispatch) => {
    await axios
        .post(`${keys.ServicePath}/api/vendor`, data)
        .then((res) => {
            if (callback?.onSuccess) callback.onSuccess(res.data.rows);
        })
        .catch((e) => {
            if (callback?.onError) callback?.onError(e);
        });
};

export const delete_vendor = (data, callback) => async (dispatch) => {
    await axios
        .post(`${keys.ServicePath}/api/vendor/delete`, data)
        .then((res) => {
            if (callback?.onSuccess) callback.onSuccess(res.data.rows);
        })
        .catch((e) => {
            if (callback?.onError) callback?.onError(e);
        });
};