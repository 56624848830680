import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";

import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  one_column_grid,
  textFieldProps,
  two_column_grid
} from "../../common/controlProps";
import { useCategoryHooks } from "./useCategoryHooks";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import { Cancel, Save } from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";
import FileToBase64 from "../../components/FileBaseTo64";
import DeleteModal from "../../components/DeleteModal";

const ProductCategory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    formik,
    category_list,
    actions,
    column,
    formData,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useCategoryHooks();

  return (
    <div>
      <MainCard title="Category Master">
        <Grid container spacing={1}>
          <Grid item xl={12} xs={12} sm={12} md={12}>
            <Form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item {...four_column_grid}>
                  <TextField
                    {...textFieldProps}
                    type="text"
                    label="Category"
                    name="category"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    error={formik.errors.category}
                    helperText={
                      formik.touched.category && formik.errors?.category
                    }
                  />
                </Grid>
                <Grid item {...four_column_grid}>
                  <Autocomplete
                    name="parentcategory"
                    size="small"
                    options={category_list}
                    getOptionLabel={(option) => option.label || null}
                    value={formik.values.parentcategory}
                    onChange={actions.handleDDChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...textFieldProps}
                        onBlur={formik.handleBlur}
                        label="Parent Category"
                      />
                    )}
                  />
                </Grid>
                <Grid item {...four_column_grid}>
                  <TextField
                    disabled
                    label={`File name`}
                    helperText={"(Max size 150 KB)"}
                    InputLabelProps={{ shrink: true }}
                    value={(formData.file && formData.file?.name) || ""}
                    {...textFieldProps}
                  />
                </Grid>
                <Grid item {...two_column_grid}>
                  <FileToBase64 onChange={actions.onFileChange} />
                </Grid>
                <Grid item {...two_column_grid}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isfeatured"
                        color="success"
                        size="small"
                        checked={formik.values.isfeatured}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Featured?"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item {...two_column_grid}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="displayonweb"
                        color="success"
                        size="small"
                        checked={formik.values.displayonweb}
                        onChange={formik.handleChange}
                      />
                    }
                    label="Display on web?"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={1}>
                <Grid item {...button_grid}>
                  <StyledButton
                    {...buttonFieldProps}
                    type="submit"
                    color="success"
                    startIcon={<Save />}
                  >
                    Save
                  </StyledButton>
                </Grid>
                <Grid item {...button_grid}>
                  <StyledButton
                    {...buttonFieldProps}
                    type="button"
                    color="error"
                    startIcon={<Cancel />}
                  >
                    {`Cancel`}
                  </StyledButton>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
        <Grid item xl={12} md={12} sm={12} className="border-1">
          <div className="border border-secondary-subtle mt-2">
            <TableContainer
              columns={column}
              data={category_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        </Grid>
        <DeleteModal
          deleteModal={show}
          handleDeleteRequest={actions.onGridDelete}
          setDeleteModal={(show) =>
            setDeleteContainer((prev) => ({ ...prev, show }))
          }
          deleteLoading={isDeleting}
          centered={true}
        />
      </MainCard>
    </div>
  );
};

export default ProductCategory;
