import { SALES_LIST } from "../constTypes";

export const sales_list = (state = [], action) => {
  switch (action.type) {
    case SALES_LIST:
      return action.payload || [];
    default:
      return state;
  }
};
