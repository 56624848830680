import { Grid } from "@mui/material";
import MainCard from "../../components/MainCard";
import TableContainer from "../../components/TableComponent/TableContainer";

import DeleteModal from "../../components/DeleteModal";

import { useNavigate } from "react-router";
import { useBlogListHooks } from "./useBloghooks";
import StyledButton from "../../components/Button";
import { buttonFieldProps } from "../../common/controlProps";
import { Book } from "@mui/icons-material";

const BlogList = () => {
  const navigate = useNavigate();
  const {
    blogs_list,
    column,
    actions,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = useBlogListHooks();

  return (
    <MainCard title="Blogs List">
      <Grid container spacing={2}>
        <Grid item md={2} lg={2}>
          <StyledButton
            {...buttonFieldProps}
            type="button"
            color="success"
            startIcon={<Book />}
            onClick={() => navigate("/app/addblogs")}
          >
            {`Add New Blog`}
          </StyledButton>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={1}>
        <Grid item xl={12} md={12} sm={12} className="border-1">
          <div className="border border-secondary-subtle mt-2">
            <TableContainer
              columns={column}
              data={blogs_list}
              isGlobalFilter={false}
              showColumnFilters={false}
              isAddUserList={false}
              showFooter={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        </Grid>
      </Grid>

      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
    </MainCard>
  );
};

export default BlogList;
