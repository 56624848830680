import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fade,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography
} from "@mui/material";

import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";

import {
  buttonFieldProps,
  button_grid,
  four_column_grid,
  one_column_grid,
  textFieldProps,
  two_column_grid
} from "../../common/controlProps";
import { useProductMasterHooks, useProductMasterListHooks } from "./useProductMasterHooks";
import { Form } from "reactstrap";
import StyledButton from "../../components/Button";
import {
  Add,
  AddShoppingCart,
  Cancel,
  Delete,
  Save
} from "@mui/icons-material";
import TableContainer from "../../components/TableComponent/TableContainer";
import FileToBase64 from "../../components/FileBaseTo64";
import DeleteModal from "../../components/DeleteModal";
import { useState } from "react";
import { purple } from "@mui/material/colors";
import { useNavigate } from "react-router";

const ProductList = () => {
  const navigate = useNavigate();
  const {
    formik,
    product_list,
    category_list,
    actions,
    column,
    formData,
    product_images,
    pi_column,
    attribute_master_value_list,
    selectedattribute,
    selectedattributevalue,
    style,
    open,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    },
    deleteProduct: { deleteProductContainer, setDeleteProductContainer }
  } = useProductMasterListHooks();

  return (
    <MainCard title="Product Master">
      <Grid container spacing={2}>
        <Grid item md={2} lg={2}>
          <StyledButton
            {...buttonFieldProps}
            type="button"
            color="success"
            startIcon={<Add />}
            onClick={() =>
              navigate("/app/productmaster", { state: { formData } })
            }
          >
            {`Add New Product`}
          </StyledButton>
        </Grid>
      </Grid>
      <Grid item xl={12} md={12} sm={12} className="border-1">
        <div className="border border-secondary-subtle mt-2">
          <TableContainer
            columns={column}
            data={product_list}
            isGlobalFilter={true}
            showColumnFilters={true}
            isAddUserList={false}
            tableClass={"table-sm"}
            divClass={"table-responsive"}
            showPagination={true}
            initialState={{ pageSize: 10, pageIndex: 0 }}
          />
        </div>
      </Grid>
      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={actions.handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xl={12} xs={12} sm={12} md={12}>
                <Typography
                  variant="h4"
                  color={purple[300]}
                >{`Products frequently bought together `}</Typography>
                <Divider
                  className="mt-2"
                  style={{
                    borderColor: purple[500],
                    borderWidth: "1px",
                    opacity: "0.8"
                  }}
                />
              </Grid>

              <Grid item xl={12} xs={12} sm={12} md={12}>
                <Autocomplete
                  name="products"
                  size="small"
                  multiple
                  options={product_list}
                  getOptionLabel={(option) => option?.label}
                  value={formik.values.frequent_products}
                  onChange={actions.onFrequentProductsAdd}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...textFieldProps}
                      onBlur={formik.handleBlur}
                      label="Products"
                      placeholder="Frequently bought together"
                      // error={formik.errors.attribute}
                    />
                  )}
                />
              </Grid>
              <Grid item xl={4} xs={12} sm={12} md={4}>
                <Button
                  {...buttonFieldProps}
                  className="custom-btn-page-layout custom-btn-deeppurple"
                  type="button"
                  startIcon={<AddShoppingCart />}
                  onClick={actions.handleClose}
                >
                  {"Add/Update Frequent products"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </MainCard>
  );
};

export default ProductList;
