/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productValidationSchema } from "./validationSchema";
import { useFormik } from "formik";
import { get_category_list } from "../../store/actions/productCategoryAction";
import { GridAction } from "../../common/useGridActions";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  Circle,
  Image
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { keys } from "../../config/keys";
import { ImageIcon } from "../../Icons";
import {
  delete_product,
  delete_product_images,
  get_attribute_master_value_list,
  get_product_list,
  save_product
} from "../../store/actions/productAction";

const initialValues = {
  id: 0,
  sku: "",
  productname: "",
  categoryid: 0,
  category: null,
  isfeatured: false,
  isactive: true,
  file: undefined,
  documentsize: 0,
  documenttype: "",
  tagline: "",
  description: "",
  product_images: [],
  attribute_values: [],
  frequent_products: [],
  specification_description: "",
  product_specifications: []
};

const product_image_initialvalue = {
  id: Math.random(),
  image_title: "",
  image_description: "",
  isdefault: false,
  isthumbnail: false,
  file: undefined,
  documentsize: 0,
  documenttype: ""
};

const product_specification_initialvalue = {
  id: Math.random(),
  specification_description: "",
  specification_type: "",
  specification_value: "",
  isdelete: 0
};

export const useProductMasterHooks = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { product_list, category_list, attribute_master_value_list } =
    useSelector((obj) => obj);

  const [formData, setFormData] = useState(
    location?.state?.id > 0 ? location.state : initialValues
  );
  const [selectedattribute, setselectedattribute] = useState(null);
  const [selectedattributevalue, setselectedattributevalue] = useState(null);
  const [product_images, setProductImages] = useState(
    product_image_initialvalue
  );
  const [product_specifications, setProductSpecifications] = useState(
    product_specification_initialvalue
  );
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });
  const [deleteProductContainer, setDeleteProductContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_product_list = () => dispatch(get_product_list());
  const _get_category_list = () => dispatch(get_category_list());
  const _get_attribute_master_value_list = () =>
    dispatch(get_attribute_master_value_list());

  const handleSubmit = (values) => {
    const { attribute_values, frequent_products, ...others } = values;

    const data = {
      ...others,
      attributes: [].concat(...attribute_values.map((x) => x.attribute_values)),
      frequent_products: [].concat(...frequent_products?.map((x) => x.id))
    };

    dispatch(
      save_product(data, {
        onSuccess: () => {
          _get_product_list();
          setFormData({ ...initialValues });
          formik.setValues({ ...initialValues });
          formik.resetForm();
        }
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: productValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  useEffect(() => {
    _get_product_list();
    _get_category_list();
    _get_attribute_master_value_list();
  }, []);

  useEffect(() => {
    if (location.state.id > 0) {
    
      setFormData((prev) => ({ ...prev, ...location.state }));
    }
  }, [location.state]);

  const handleDDChange = (e, val) => {
    formik.setFieldValue("category", val);
    formik.setFieldValue("categoryid", val === null ? 0 : val?.id);
  };

  const handleAttributeChange = (e, val) => {
    setselectedattribute(val || null);
    setselectedattributevalue([]);
  };

  const handleAttributeValueChange = (e, val) => {
    setselectedattributevalue(val || null);
  };

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_product(data, {
          onSuccess: () => {
            _get_product_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
          }
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku"
      },
      {
        Header: "Product",
        accessor: "productname"
      },
      {
        Header: "Category",
        accessor: "category"
      },
      {
        Header: "Featured?",
        accessor: "isfeatured",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isfeatured ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "Active ?",
        accessor: "isctive",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isactive ? (
            <Circle fontSize="small" color={"success"} />
          ) : (
            <Circle fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "View Image",
        accessor: "productimage",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.productimage !== null ? (
            <Link
              aria-disabled={true}
              target="_blank"
              to={`${keys.ServicePath}/${cellProps.row.original.productimage}`}
              className="align-center"
              title={cellProps.row.original.productimage}
              aria-label={cellProps.row.original.productimage}
            >
              <ImageIcon fontSize="medium" />
            </Link>
          ) : (
            <Image fontSize="small" htmlColor="#dddddd  " />
          );
        }
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            const { categoryid } = cellProps.row.original;
            setFormData((prev) => ({
              ...prev,
              ...cellProps.row.original,
              file: {
                name:
                  cellProps.row.original.productimage !== null
                    ? cellProps.row.original.productimage.split("/").pop()
                    : ""
              },
              isfeatured: Boolean(cellProps.row.original.isfeatured),
              isactive: Boolean(cellProps.row.original.isactive),
              category:
                categoryid > 0
                  ? category_list.find((x) => x.id === categoryid)
                  : null
            }));

            // console.log(category_list, categoryid,category_list.find((x) => x.id === categoryid))
            // formik.setValues({
            //   ...cellProps.row.original,
            //   isfeatured: Boolean(cellProps.row.original.isfeatured),
            //   displayonweb: Boolean(cellProps.row.original.isactive)
            // });
            // navigate(`/addgrn`, { state: { id: cellProps.row.original.id } });
          },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  const onFileChange = (result) => {
    formik.setFieldValue("file", result);
    formik.setFieldValue("documentsize", result?.size);
    formik.setFieldValue("documenttype", result?.type);
    setFormData((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type
    }));
  };

  const resetForm = () => {
    setFormData({ ...initialValues });
    navigate("/app/productlist");
  };

  const onPIFileChange = (result) => {
    // formik.setFieldValue("file", result);
    // formik.setFieldValue("documentsize", result?.size);
    // formik.setFieldValue("documenttype", result?.type);
    // setFormData((prev) => ({
    //   ...prev,
    //   file: result,
    //   documentsize: result?.size,
    //   documenttype: result?.type
    // }));
    setProductImages((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type
    }));
  };

  const handleChangeImageData = (e) => {
    setProductImages((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleChangeImageChecked = (e) => {
    setProductImages((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked
    }));
  };

  const onAddClick = () => {
    let tmp = formData.product_images || [];
    const p_images = [...tmp];
    p_images.push({ ...product_images, id: Math.random() });
    setFormData((prev) => ({
      ...prev,
      product_images: p_images
    }));

    setProductImages({ ...product_image_initialvalue });
  };

  const onGridProductDelete = () => {
    const { data } = deleteProductContainer;
    //console.log("product data", data);

    const t = formData.product_images.filter((x) => x.id !== data.id);
    setFormData((prev) => ({
      ...prev,
      product_images: [...t]
    }));

    setDeleteProductContainer({
      show: false,
      isDeleting: false,
      data
    });
    if (data?.id >= 1) {
      dispatch(
        delete_product_images(data, {
          onSuccess: () => {}
        })
      );
    }
  };

  const pi_column = useMemo(
    () => [
      {
        Header: "Image Title",
        accessor: "image_title"
      },
      {
        Header: "Description",
        accessor: "image_description"
      },
      {
        Header: "Default",
        accessor: "isdefault",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isdefault ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "Thumbnail?",
        accessor: "isthumbnail",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isthumbnail ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "type",
        accessor: "documenttype"
      },
      {
        id: "p_image",
        Header: "",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.documenttype.includes("video") ? (
            <video width="30" controls>
              <source
                src={
                  cellProps.row.original.productid > 0
                    ? `${keys.ServiceIP}/${cellProps.row.original.filepath}`
                    : cellProps.row.original.file.base64
                }
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              src={
                cellProps.row.original.productid > 0
                  ? `${keys.ServiceIP}/${cellProps.row.original.filepath}`
                  : cellProps.row.original.file.base64
              }
              alt="p_image"
              width={30}
            />
          );
        }
      },
      {
        ...GridAction({
          permission: { edit: false, delete: true },

          onDeleteClick: (cellProps) => {
            setDeleteProductContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  const onAddAttributesClick = () => {
    let tmp = formData.attribute_values || [];

    const p_images = [
      ...selectedattributevalue.map((x) => ({ ...x, idx: Math.random() }))
    ];

    tmp.push({
      id: Math.random(),
      attribute: selectedattribute?.label,
      attributevalues: selectedattributevalue.map((x) => x.label).join(","),
      attribute_values: selectedattributevalue
    });

    formik.setFieldValue("attribute_values", tmp);
    setselectedattribute(null);
    setselectedattributevalue(null);
    // setFormData((prev) => ({
    //   ...prev,
    //   attribute_values: p_images
    // }));
  };

  const onDeleteAttributeClick = (data) => {
    const tempData = formData?.attribute_values.filter(
      (x) => x.id !== data?.id
    );
    formik.setFieldValue("attribute_values", tempData);
  };

  const onFrequentProductsAdd = (e, data) => {
    formik.setFieldValue("frequent_products", data);
  };

  const handleProductSpecificationChange = (e) => {
  
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    console.log("formData",formData)
  };


  const handleSpecificationChange = (e) => {

    setProductSpecifications((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onAddSpecificationClick = () => {
    let tmp = formData.product_specifications || [];
    const p_specification = [...tmp];
    p_specification.push({ ...product_specifications, id: Math.random() });
    setFormData((prev) => ({
      ...prev,
      product_specifications: p_specification
    }));

    setProductSpecifications({ ...product_specification_initialvalue });
  };

  const onDeleteSpecificationClick = (data) => {
    const deletedData = formData?.product_specifications.find(
      (x) => x.id === data?.id
    );
    deletedData.isdelete = 1;

    const tempData = [
      ...formData?.product_specifications.filter((x) => x.id !== data?.id)
    ];
    if (deletedData.id >= 1) tempData.push(deletedData);

    setFormData((prev) => ({
      ...prev,
      product_specifications: tempData
    }));
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSpecification, setSpecificationOpen] = useState(false);
  const handleSpecificationOpen = () => setSpecificationOpen(true);
  const handleSpecificationClose = () => setSpecificationOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    minHeight: "250px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };

  return {
    formik,
    formData,
    initialValues,
    product_list,
    category_list,
    column,
    product_images,
    pi_column,
    attribute_master_value_list,
    selectedattribute,
    selectedattributevalue,
    style,
    open,
    openSpecification,
    product_specifications,
    actions: {
      handleSubmit,
      onGridDelete,
      onGridProductDelete,
      handleDDChange,
      onFileChange,
      resetForm,
      onPIFileChange,
      handleChangeImageData,
      handleChangeImageChecked,
      onAddClick,
      handleAttributeChange,
      handleAttributeValueChange,
      onAddAttributesClick,
      onDeleteAttributeClick,
      onFrequentProductsAdd,
      handleOpen,
      handleClose,
      handleSpecificationOpen,
      handleSpecificationClose,
      handleSpecificationChange,
      handleProductSpecificationChange,
      onAddSpecificationClick,
      onDeleteSpecificationClick
    },
    delete: {
      deleteContainer,
      setDeleteContainer
    },
    deleteProduct: {
      deleteProductContainer,
      setDeleteProductContainer
    }
  };
};

export const useProductMasterListHooks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { product_list, category_list, attribute_master_value_list } =
    useSelector((obj) => obj);

  const [formData, setFormData] = useState(initialValues);
  const [selectedattribute, setselectedattribute] = useState(null);
  const [selectedattributevalue, setselectedattributevalue] = useState(null);
  const [product_images, setProductImages] = useState(
    product_image_initialvalue
  );
  const [deleteContainer, setDeleteContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });
  const [deleteProductContainer, setDeleteProductContainer] = useState({
    show: false,
    isDeleting: false,
    data: null
  });

  const _get_product_list = () => dispatch(get_product_list());
  const _get_category_list = () => dispatch(get_category_list());
  const _get_attribute_master_value_list = () =>
    dispatch(get_attribute_master_value_list());

  const handleSubmit = (values) => {
    const { attribute_values, frequent_products, ...others } = values;

    const data = {
      ...others,
      attributes: [].concat(...attribute_values.map((x) => x.attribute_values)),
      frequent_products: [].concat(...frequent_products?.map((x) => x.id))
    };

    dispatch(
      save_product(data, {
        onSuccess: () => {
          _get_product_list();
          setFormData({ ...initialValues });
          formik.setValues({ ...initialValues });
          formik.resetForm();
          navigate("/app/productlist");
        }
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...formData },
    validationSchema: productValidationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true
  });

  useEffect(() => {
    _get_product_list();
    _get_category_list();
    _get_attribute_master_value_list();
  }, []);

  const handleDDChange = (e, val) => {
    formik.setFieldValue("category", val);
    formik.setFieldValue("categoryid", val === null ? 0 : val?.id);
  };

  const handleAttributeChange = (e, val) => {
    setselectedattribute(val || null);
    setselectedattributevalue([]);
  };

  const handleAttributeValueChange = (e, val) => {
    setselectedattributevalue(val || null);
  };

  const onGridDelete = () => {
    const { data } = deleteContainer;
    if (data?.id > 0) {
      dispatch(
        delete_product(data, {
          onSuccess: () => {
            _get_product_list();
            setDeleteContainer({
              show: false,
              isDeleting: false,
              data: undefined
            });
          }
        })
      );
    }
  };

  const column = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku"
      },
      {
        Header: "Product",
        accessor: "productname"
      },
      {
        Header: "Category",
        accessor: "category"
      },
      {
        Header: "Current Stock",
        accessor: "stock"
      },
      {
        Header: "Featured?",
        accessor: "isfeatured",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isfeatured ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "Active ?",
        accessor: "isctive",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isactive ? (
            <Circle fontSize="small" color={"success"} />
          ) : (
            <Circle fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "View Image",
        accessor: "productimage",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.productimage !== null ? (
            <Link
              aria-disabled={true}
              target="_blank"
              to={`${keys.ServicePath}/${cellProps.row.original.productimage}`}
              className="align-center"
              title={cellProps.row.original.productimage}
              aria-label={cellProps.row.original.productimage}
            >
              <ImageIcon fontSize="medium" />
            </Link>
          ) : (
            <Image fontSize="small" htmlColor="#dddddd  " />
          );
        }
      },
      {
        ...GridAction({
          permission: { edit: true, delete: true },
          onEditClick: (cellProps) => {
            const { categoryid } = cellProps.row.original;
            setFormData((prev) => ({
              ...prev,
              ...cellProps.row.original,
              file: {
                name:
                  cellProps.row.original.productimage !== null
                    ? cellProps.row.original.productimage.split("/").pop()
                    : ""
              },
              isfeatured: Boolean(cellProps.row.original.isfeatured),
              isactive: Boolean(cellProps.row.original.isactive),
              category:
                categoryid > 0
                  ? category_list.find((x) => x.id === categoryid)
                  : null
            }));

            navigate("/app/productmaster", {
              state: {
                ...cellProps.row.original,
                file: {
                  name:
                    cellProps.row.original.productimage !== null
                      ? cellProps.row.original.productimage.split("/").pop()
                      : ""
                },
                isfeatured: Boolean(cellProps.row.original.isfeatured),
                isactive: Boolean(cellProps.row.original.isactive),
                category:
                  categoryid > 0
                    ? category_list.find((x) => x.id === categoryid)
                    : null
              }
            });
            // console.log(category_list, categoryid,category_list.find((x) => x.id === categoryid))
            // formik.setValues({
            //   ...cellProps.row.original,
            //   isfeatured: Boolean(cellProps.row.original.isfeatured),
            //   displayonweb: Boolean(cellProps.row.original.isactive)
            // });
            // navigate(`/addgrn`, { state: { id: cellProps.row.original.id } });
          },
          onDeleteClick: (cellProps) => {
            setDeleteContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setFormData((prev) => ({ ...formik.values }));
  }, [formik.values]);

  const onFileChange = (result) => {
    formik.setFieldValue("file", result);
    formik.setFieldValue("documentsize", result?.size);
    formik.setFieldValue("documenttype", result?.type);
    setFormData((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type
    }));
  };

  const resetForm = () => setFormData({ ...initialValues });

  const onPIFileChange = (result) => {
    // formik.setFieldValue("file", result);
    // formik.setFieldValue("documentsize", result?.size);
    // formik.setFieldValue("documenttype", result?.type);
    // setFormData((prev) => ({
    //   ...prev,
    //   file: result,
    //   documentsize: result?.size,
    //   documenttype: result?.type
    // }));
    setProductImages((prev) => ({
      ...prev,
      file: result,
      documentsize: result?.size,
      documenttype: result?.type
    }));
  };

  const handleChangeImageData = (e) => {
    setProductImages((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };
  const handleChangeImageChecked = (e) => {
    setProductImages((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked
    }));
  };

  const onAddClick = () => {
    let tmp = formData.product_images || [];
    const p_images = [...tmp];
    p_images.push({ ...product_images, id: Math.random() });
    setFormData((prev) => ({
      ...prev,
      product_images: p_images
    }));

    setProductImages({ ...product_image_initialvalue });
  };

  const onGridProductDelete = () => {
    const { data } = deleteProductContainer;
    //console.log("product data", data);

    const t = formData.product_images.filter((x) => x.id !== data.id);
    setFormData((prev) => ({
      ...prev,
      product_images: [...t]
    }));

    setDeleteProductContainer({
      show: false,
      isDeleting: false,
      data
    });
    if (data?.id >= 1) {
      dispatch(
        delete_product_images(data, {
          onSuccess: () => {}
        })
      );
    }
  };

  const pi_column = useMemo(
    () => [
      {
        Header: "Image Title",
        accessor: "image_title"
      },
      {
        Header: "Description",
        accessor: "image_description"
      },
      {
        Header: "Default",
        accessor: "isdefault",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isdefault ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "Thumbnail?",
        accessor: "isthumbnail",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.isthumbnail ? (
            <CheckBox fontSize="small" color={"success"} />
          ) : (
            <CheckBoxOutlineBlank fontSize="small" color={"error"} />
          );
        }
      },
      {
        Header: "type",
        accessor: "documenttype"
      },
      {
        id: "p_image",
        Header: "",
        Filter: false,
        style: {
          "text-align": "center",
          "vertical-align": "middle"
        },
        Cell: (cellProps) => {
          return cellProps.row.original.documenttype.includes("video") ? (
            <video width="30" controls>
              <source
                src={
                  cellProps.row.original.productid > 0
                    ? `${keys.ServiceIP}/${cellProps.row.original.filepath}`
                    : cellProps.row.original.file.base64
                }
                type="video/mp4"
              />
            </video>
          ) : (
            <img
              src={
                cellProps.row.original.productid > 0
                  ? `${keys.ServiceIP}/${cellProps.row.original.filepath}`
                  : cellProps.row.original.file.base64
              }
              alt="p_image"
              width={30}
            />
          );
        }
      },
      {
        ...GridAction({
          permission: { edit: false, delete: true },

          onDeleteClick: (cellProps) => {
            setDeleteProductContainer({
              show: true,
              isDeleting: false,
              data: cellProps.row.original
            });
            // setDeleteId(cellProps.row.original.id);
          }
        })
      }
    ],
    // eslint-disable-next-line
    []
  );

  const onAddAttributesClick = () => {
    let tmp = formData.attribute_values || [];

    const p_images = [
      ...selectedattributevalue.map((x) => ({ ...x, idx: Math.random() }))
    ];

    tmp.push({
      id: Math.random(),
      attribute: selectedattribute?.label,
      attributevalues: selectedattributevalue.map((x) => x.label).join(","),
      attribute_values: selectedattributevalue
    });

    formik.setFieldValue("attribute_values", tmp);
    setselectedattribute(null);
    setselectedattributevalue(null);
    // setFormData((prev) => ({
    //   ...prev,
    //   attribute_values: p_images
    // }));
  };

  const onDeleteAttributeClick = (data) => {
    const tempData = formData?.attribute_values.filter(
      (x) => x.id !== data?.id
    );
    formik.setFieldValue("attribute_values", tempData);
  };

  const onFrequentProductsAdd = (e, data) => {
    formik.setFieldValue("frequent_products", data);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    minHeight: "250px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };

  return {
    formik,
    formData,
    initialValues,
    product_list,
    category_list,
    column,
    product_images,
    pi_column,
    attribute_master_value_list,
    selectedattribute,
    selectedattributevalue,
    style,
    open,
    actions: {
      handleSubmit,
      onGridDelete,
      onGridProductDelete,
      handleDDChange,
      onFileChange,
      resetForm,
      onPIFileChange,
      handleChangeImageData,
      handleChangeImageChecked,
      onAddClick,
      handleAttributeChange,
      handleAttributeValueChange,
      onAddAttributesClick,
      onDeleteAttributeClick,
      onFrequentProductsAdd,
      handleOpen,
      handleClose
    },
    delete: {
      deleteContainer,
      setDeleteContainer
    },
    deleteProduct: {
      deleteProductContainer,
      setDeleteProductContainer
    }
  };
};
