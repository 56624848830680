import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import MainCard from "../../components/MainCard";
import { tokens } from "../../theme";
import { usePurchaseMasterListHooks } from "./usePurchaseMasterHooks";
import TableContainer from "../../components/TableComponent/TableContainer";
import DeleteModal from "../../components/DeleteModal";
import StyledButton from "../../components/Button";
import { buttonFieldProps } from "../../common/controlProps";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router";

const PurchaseMaster = () => {
  const navigate= useNavigate();
  const {
    purchase_list,
    actions,
    column,
    delete: {
      deleteContainer: { show, isDeleting },
      setDeleteContainer
    }
  } = usePurchaseMasterListHooks();

  return (
    <MainCard title="Purchase Master">
      <Grid container spacing={2}>
        <Grid item md={2} lg={2}>
          <StyledButton
            {...buttonFieldProps}
            type="button"
            color="success"
            startIcon={<Add />}
            onClick={() => navigate("/app/purchase/add")}
          >
            {`Add New Purchase`}
          </StyledButton>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={1}>
        <Grid item xl={12} md={12} sm={12} className="border-1">
          <div className="border border-secondary-subtle mt-2">
            <TableContainer
              columns={column}
              data={purchase_list}
              isGlobalFilter={true}
              showColumnFilters={true}
              isAddUserList={false}
              tableClass={"table-sm"}
              divClass={"table-responsive"}
              showPagination={true}
              initialState={{ pageSize: 10, pageIndex: 0 }}
            />
          </div>
        </Grid>
      </Grid>
      <DeleteModal
        deleteModal={show}
        handleDeleteRequest={actions.onGridDelete}
        setDeleteModal={(show) =>
          setDeleteContainer((prev) => ({ ...prev, show }))
        }
        deleteLoading={isDeleting}
        centered={true}
      />
    </MainCard>
  );
};

export default PurchaseMaster;
