const USER_LIST = "USER_LIST";
const USER_TYPES = "USER_TYPES";
const CATEGORY_LIST = "CATEGORY_LIST";
const PRODUCT_LIST = "PRODUCT_LIST";
const VENDOR_LIST = "VENDOR_LIST";
const PURCHASE_LIST ="PURCHASE_LIST";
const CUSTOMER_LIST ="CUSTOMER_LIST";
const SALES_LIST ="PURCHASE_LIST";
const BLOG_LIST = "BLOG_LIST";
const ATTRIBUTE_MASTER_VALUE_LIST = "ATTRIBUTE_MASTER_VALUE_LIST";

export {
    USER_LIST,
    USER_TYPES,
    CATEGORY_LIST,
    PRODUCT_LIST,
    VENDOR_LIST,
    PURCHASE_LIST,
    CUSTOMER_LIST,
    SALES_LIST,
    BLOG_LIST,
    ATTRIBUTE_MASTER_VALUE_LIST
}